import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ModalButton } from './button/ModalButton';
import { getSettings } from '../services/api';


const OrderKot = forwardRef(
  (
    props: {
      order: any;
    },
    ref
  ) => {
    return (
      <Box ref={ref} style={{ width: '100%', borderRadius: '5px', padding: "0 10px", margin: 0 }}>
        <Typography variant="h6" align="center" style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>
          Ashirwad Hotel Restaurant
        </Typography>
        <Typography variant="subtitle1" align="center" style={{ margin: 0, padding: 0, fontWeight: '700', textTransform: 'uppercase' }}>
          Name - {props?.order?.userMap?.user?.first_name || ''}
        </Typography>
        <Divider style={{ margin: '2px 0' }} />
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>Order No:</TableCell>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>{props?.order?.orderNumber}</TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>Order Id:</TableCell>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>{props?.order?.order_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>Order Date:</TableCell>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>
                  {moment(props?.order?.createdAt).format('YYYY-MM-DD')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>Time:</TableCell>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>
                  {moment(props?.order?.createdAt).format('HH:mm:ss A')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>Instructions:</TableCell>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>{props?.order?.instructions || ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ padding: 0 }}>
                  <Divider style={{ margin: '2px 0' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>Item</TableCell>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>Quantity</TableCell>
              </TableRow>
              {props?.order &&
                props?.order?.items?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>
                      {item?.parent
                        ? item?.parent?.name + ' (' + item?.name + ')'
                        : item?.name}
                    </TableCell>
                    <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>{item?.quantity}</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={2} style={{ padding: 0 }}>
                  <Divider style={{ margin: '2px 0', fontWeight: 'bold' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>Total Items:</TableCell>
                <TableCell style={{ padding: '2px', fontWeight: 'bold' }}>{props?.order?.items?.length}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
);


const OrderPrint = forwardRef(
  (
    props: {
      order: any;
    },
    ref
  ) => {
    const [settings, setSettings] = useState<any>();

    const fetchSettings = async () => {
      const response = await getSettings();
      if(response?.status === 200){
        setSettings(response?.data);
      }
    };

    useEffect(() => {
      fetchSettings();
    }, []);


    return (
      <Box
        ref={ref}
        style={{
          width: '100%',
          borderRadius: '5px',
          marginTop: '0',
          padding: '0',
        }}
      >
        <Typography variant="h5" align="center" style={{ margin: 0, padding: '4px 0' }}>
          Tax Invoice
        </Typography>
        <Typography variant="h6" align="center" style={{ margin: 0, padding: '4px 0', fontWeight: 'bold' }}>
          {settings?.restaurant_name}
        </Typography>
        <Typography variant="body2" align="center" style={{ margin: 0, padding: '2px 0' }}>
          ({settings?.restaurant_address})
        </Typography>
        <Stack direction={'row'} gap={1} px={1} mt={1} mb={1}>
          <Typography variant="body2" align="left" style={{ margin: 0, padding: 0 }}>
            Contact Number
          </Typography>
          <Typography variant="body2" align="right" style={{ margin: 0, padding: 0 }}>
            {settings?.help_mob}
          </Typography>
        </Stack>
        <Divider style={{ margin: '2px 0' }} />
        <Typography variant="h6" align="center" style={{ margin: 0, padding: '4px 0' }}>
          *Home Delivery Bill*
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Order No:</TableCell>
                <TableCell style={{ padding: '2px' }}>{props?.order?.orderNumber}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell style={{ padding: '2px' }}>Order Id:</TableCell>
              <TableCell style={{ padding: '2px' }}>{props?.order?.order_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Order Date:</TableCell>
                <TableCell style={{ padding: '2px' }}>
                  {moment(props?.order?.createdAt).format('YYYY-MM-DD HH:mm:ss A')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Operator Id</TableCell>
                <TableCell style={{ padding: '2px' }}>Manager</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Customer Name</TableCell>
                <TableCell style={{ padding: '2px', textTransform: 'uppercase' }}>{props?.order?.userMap?.user?.first_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Address</TableCell>
                <TableCell style={{ padding: '2px' }}>
                  {props?.order?.address?.street} {props?.order?.address?.building} {props?.order?.address?.near_by}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Contact No</TableCell>
                <TableCell style={{ padding: '2px' }}>{props?.order?.address?.receiver_mobile}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell style={{ padding: '2px' }}>Delivery Person</TableCell>
                <TableCell style={{ padding: '2px' }}>
                  {props?.order?.riderOrder?.rider_map?.user?.first_name}
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell colSpan={4} style={{ padding: 0 }}>
                  <Divider style={{ margin: '2px 0' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Item</TableCell>
                <TableCell style={{ padding: '2px' }}>Quantity</TableCell>
                <TableCell style={{ padding: '2px' }}>Amount</TableCell>
              </TableRow>
              {props?.order?.items?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={{ padding: '2px' }}>
                    {item?.parent ? `${item?.parent?.name} (${item?.name})` : item?.name}
                  </TableCell>
                  <TableCell style={{ padding: '2px' }}>{item?.quantity}</TableCell>
                  <TableCell style={{ padding: '2px' }}>{item?.discountPrice * item?.quantity}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} style={{ padding: 0 }}>
                  <Divider style={{ margin: '2px 0' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Total Items: {props?.order?.items?.length}</TableCell>
                <TableCell style={{ padding: '2px' }}>
                  Quantity:{' '}
                  {props.order.items.reduce((acc, item) => acc + item.quantity, 0)}
                </TableCell>
                <TableCell style={{ padding: '2px', fontWeight: 700, display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                 {props?.order?.payment?.amount}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} style={{ padding: 0 }}>
                  <Divider style={{ margin: '2px 0' }} />
                </TableCell>
              </TableRow>
             {
              props?.order?.payment?.paymentType === 'online' && (
                <>
                <TableRow>
                  <TableCell style={{ padding: '2px', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    Payment mode: {" "} {props?.order?.payment?.paymentType?.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell style={{ padding: '2px', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    Payment Id: {" "} {props?.order?.payment?.payment_id}
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell style={{ padding: '2px', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    Payment Status: {" "} {props?.order?.payment?.status?.toUpperCase()}
                  </TableCell>
                </TableRow>
                </>
              )
             }
              <TableRow>
                <TableCell style={{ padding: '2px', fontWeight: 700, display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                  Amount to Pay: {" "} {props?.order?.payment?.amount}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Item Total:</TableCell>
                <TableCell style={{ padding: '2px' }}>
                  {props.order.items.reduce((acc, item) => acc + item.discountPrice * item.quantity, 0)}
                </TableCell>
              </TableRow>
              {props?.order?.gst !== 0 && <TableRow sx={{width: '100%'}}>
                <TableCell style={{ padding: '2px' }}>GST: </TableCell>
                <TableCell style={{ padding: '2px' }}>{props?.order?.gst}</TableCell>
              </TableRow>}
              {props?.order?.deliveryFee !== 0 && <TableRow sx={{width: '100%'}}>
                <TableCell style={{ padding: '2px' }}>Delivery fee: </TableCell>
                <TableCell style={{ padding: '2px' }}>{props?.order?.deliveryFee}</TableCell>
              </TableRow>}
              {props?.order?.platformFee !== 0 && <TableRow sx={{width: 200}}>
                <TableCell style={{ padding: '2px' }}>Packing charges: </TableCell>
                <TableCell style={{ padding: '2px' }}>{props?.order?.platformFee}</TableCell>
              </TableRow>}
              <TableRow>
                <TableCell colSpan={2} style={{ padding: 0 }}>
                  <Divider style={{ margin: '2px 0' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>Total</TableCell>
                <TableCell style={{ padding: '2px' }}>{props?.order?.payment?.amount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ padding: 0 }}>
                  <Divider style={{ margin: '2px 0' }} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h6" align="center" style={{ margin: 0, padding: '4px 0' }}>
          Thanks And Order Again
        </Typography>
      </Box>
    );
  }
);


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  border: 'none',
  borderRadius: 0,
  width: '90%',
  maxWidth: '600px',
};

export default function OrderKotModal({ open, setOpen, order }) {
  const componentRef = React.useRef();
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleKotPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ padding: '0px' }}
      >
        <Box sx={style}>
          <Stack direction='row' justifyContent='space-between'>
            <span style={{ color: '#3D4152', fontWeight: '600' }}>
              Print Kot
            </span>
            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </span>
          </Stack>
          <Divider />
          <OrderKot ref={componentRef} order={order} />
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Stack direction={'row'} spacing={2}>
              <ModalButton
                title={'Close'}
                color={'secondary.main'}
                disabled={false}
                onClick={handleCloseModal}
              />
              <Button
                variant='outlined'
                onClick={() => handleKotPrint()}
                startIcon={<LocalPrintshopIcon />}
              >
                Print Kot
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export const OrderPrintModal = ({ open, setOpen, order }) => {
  const componentRef = React.useRef();
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOrderPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{
          padding: '0px',
          overflowY: 'auto',
        }}
      >
        <Box sx={style}>
          <Stack direction='row' justifyContent='space-between'>
            <span style={{ color: '#3D4152', fontWeight: '600' }}>
              Print Order
            </span>
            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </span>
          </Stack>
          <Divider />
          <OrderPrint ref={componentRef} order={order} />
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Stack direction={'row'} spacing={2}>
              <ModalButton
                title={'Close'}
                color={'secondary.main'}
                disabled={false}
                onClick={handleCloseModal}
              />
              <Button
                variant='outlined'
                onClick={() => handleOrderPrint()}
                startIcon={<LocalPrintshopIcon />}
              >
                Print Order
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
