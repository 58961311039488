import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface authProps {
    accessToken:string;
}

const initialState:authProps = {
    accessToken:''
}

export const authSlice = createSlice({
    name:"authUser",
    initialState,
    reducers: {
        setAuthDetails (state, {payload}) {
            state.accessToken = payload;
        },
        clearAuthDetails (state, {}) {
            state.accessToken = null;
        }
    }
})


export const {
    setAuthDetails,
    clearAuthDetails
} = authSlice.actions;
export default authSlice.reducer;