import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { ModalButton } from './button/ModalButton';
import { createUser } from '../services/api';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  border: 'none',
  borderRadius: 0,
  width: '90%',
  maxWidth: '600px',
};

export default function BasicRiderModal({ open, setOpen, data, isEdit }) {
  const [formData, setFormData] = useState({
    mobile: '',
    first_name: '',
    last_name: '',
    email: '',
    role: 'rider',
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: e.target.value,
    }));
  };

  const handSubmit = async () => {
    const response = await createUser(formData);
    if(response?.status === 201) {
      toast("Rider added successfully");
    } else{ 
      toast(response?.data?.customMessage || "Could not add rider");
    };
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ padding: '0px' }}
      >
        <Box sx={style}>
          <Stack direction='row' justifyContent='space-between'>
            <span style={{ color: '#3D4152', fontWeight: '600' }}>
              Add Rider
            </span>
            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </span>
          </Stack>
          <form style={{ marginBottom: '10px' }}>
            <InputLabel
              htmlFor='item-name'
              sx={{ fontSize: '15px', marginBottom: '3px' }}
            >
              First Name
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id='item-name'
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data && data?.rider_map?.first_name}
                onChange={(e) => handleInputChange(e, 'first_name')}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: '7px',
                    paddingX: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fc8019',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </FormControl>
            <InputLabel
              htmlFor='item-name'
              sx={{ fontSize: '15px', marginBottom: '3px' }}
            >
              Last Name
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id='item-name'
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data && data?.rider_map?.last_name}
                onChange={(e) => handleInputChange(e, 'last_name')}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: '7px',
                    paddingX: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fc8019',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </FormControl>
            <InputLabel
              htmlFor='item-name'
              sx={{ fontSize: '15px', marginBottom: '3px' }}
            >
              Mobile
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                inputProps={{maxLength: 10}}
                id='item-name'
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data && data?.rider_map?.mobile}
                onChange={(e) => handleInputChange(e, 'mobile')}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: '7px',
                    paddingX: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fc8019',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </FormControl>
            <InputLabel
              htmlFor='item-name'
              sx={{ fontSize: '15px', marginBottom: '3px' }}
            >
              Email
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id='item-name'
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data && data?.rider_map?.email}
                onChange={(e) => handleInputChange(e, 'email')}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: '7px',
                    paddingX: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fc8019',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </FormControl>
          </form>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: isEdit ? 'space-between' : 'flex-end',
            }}
          >
            {isEdit && (
              <ModalButton
                title={'Delete'}
                color={'error.main'}
                disabled={false}
                onClick={() => {}}
              />
            )}

            <Stack direction={'row'} spacing={2}>
              <ModalButton
                title={'Close'}
                color={'secondary.main'}
                disabled={false}
                onClick={handleCloseModal}
              />
              <ModalButton
                title={'Save Changes'}
                color={'primary.main'}
                disabled={false}
                onClick={handSubmit}
              />
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
