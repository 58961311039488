import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { ModalButton } from './button/ModalButton';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  border: 'none',
  borderRadius: 0,
  width: '90%',
};

export default function BasicRiderOrdersModal({ open, setOpen, data }) {
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ padding: '0px' }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: 'rgb(252, 128, 25)',
                fontWeight: 700,
                fontSize: '1.3rem',
              }}
            >
              Orders
            </Typography>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: '10px',
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                        <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                          Order Id
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                        <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                          Order Number
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                        <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                          Payment Amount
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                        <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                          Payment Mode
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                        <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                          Assigned At
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                        <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                          Status
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.length > 0 &&
                      data.map((order, index) => (
                        <TableRow key={index}>
                          <TableCell
                            style={{
                              borderBottom: '1px solid #ccc',
                              color: '#848684',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography style={{ fontSize: '13px' }}>
                              {order?.order?.order_id}{' '}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: '1px solid #ccc',
                              color: '#848684',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography style={{ fontSize: '13px' }}>
                              {order?.order?.orderNumber}{' '}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: '1px solid #ccc',
                              color: '#848684',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography style={{ fontSize: '13px' }}>
                              {order?.order?.payment?.amount}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: '1px solid #ccc',
                              color: '#848684',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography style={{ fontSize: '13px' }}>
                              {order?.order?.payment?.paymentType?.toUpperCase()}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: '1px solid #ccc',
                              color: '#848684',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography style={{ fontSize: '13px' }}>
                              {order?.assignedAt}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: '1px solid #ccc',
                              color: '#848684',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: '13px',
                                color: 'green',
                                fontWeight: 700,
                              }}
                            >
                              {order?.status?.toUpperCase()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Stack direction={'row'} spacing={2}>
              <ModalButton
                title={'Close'}
                color={'secondary.main'}
                disabled={false}
                onClick={handleCloseModal}
              />
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
