import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { ChangeEvent, useEffect, useState } from 'react';
import { ModalButton } from './button/ModalButton';
import { updateRefund } from '../services/api';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  border: 'none',
  borderRadius: 0,
  width: '90%',
  maxWidth: '600px',
};

export default function BasicUpdateOrderModal({ open, setOpen, data }) {
  const [formData, setFormData] = useState({
    razorpay_refund_id: '',
    refund_status: 'fully_refunded',
    amount: 0,
    order_id: '',
  });
  const [refundStatusSelected, setRefundStatusSelected] =
    useState('fully_refunded');

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      amount: data?.payment?.amount,
    }));
  }, [data]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: e.target.value,
    }));
  };

  const handSubmit = async () => {
    if (formData?.amount <= 0) {
      toast(`amount cannot be zero,${formData?.amount}`);
      return;
    }
    if (formData?.razorpay_refund_id === '') {
      toast('refund id cannot be empty');
      return;
    }
    const response = await updateRefund({
      ...formData,
      order_id: data?.order_id,
    });
    if (response?.status === 201) {
      toast('Refund updated successfully');
    } else {
      toast(response?.data?.customMessage || 'Could not update refund');
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ padding: '0px' }}
      >
        <Box sx={style}>
          <Stack direction='row' justifyContent='space-between'>
            <span style={{ color: '#3D4152', fontWeight: '600' }}>
              Update Order - #{data?.order_id}
            </span>
            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </span>
          </Stack>
          <form style={{ marginBottom: '10px' }}>
            <InputLabel
              htmlFor='item-name'
              sx={{ fontSize: '15px', marginBottom: '3px' }}
            >
              Refund Payment Id
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id='item-name'
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data && data?.rider_map?.first_name}
                onChange={(e) => handleInputChange(e, 'razorpay_refund_id')}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: '7px',
                    paddingX: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fc8019',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </FormControl>

            <InputLabel
              htmlFor='item-name'
              sx={{ fontSize: '15px', marginBottom: '3px', marginTop: '10px' }}
            >
              Refund Status
            </InputLabel>
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={refundStatusSelected === 'fully_refunded'}
                    onChange={() => setRefundStatusSelected('fully_refunded')}
                  />
                }
                label='Fully Refunded'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={refundStatusSelected === 'partially_refunded'}
                    onChange={() =>
                      setRefundStatusSelected('partially_refunded')
                    }
                  />
                }
                label='Partially Refunded'
              />
            </Box>

            <InputLabel
              htmlFor='item-name'
              sx={{ fontSize: '15px', marginBottom: '3px' }}
            >
              Refund Amount
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id='item-name'
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={refundStatusSelected !== 'partially_refunded'}
                defaultValue={data && data?.payment?.amount}
                onChange={(e) => handleInputChange(e, 'amount')}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: '7px',
                    paddingX: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fc8019',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </FormControl>
          </form>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Stack direction={'row'} spacing={2}>
              <ModalButton
                title={'Close'}
                color={'secondary.main'}
                disabled={false}
                onClick={handleCloseModal}
              />
              <ModalButton
                title={'Save Changes'}
                color={'primary.main'}
                disabled={false}
                onClick={handSubmit}
              />
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
