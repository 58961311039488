import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  InputLabel,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ModalButton } from '../../components/button/ModalButton';
import { CategoryDropdown } from '../../components/dropdown/DropDown';
import { Loading } from '../../components/loading/Loading';
import { createBanner, deleteBanner, getAllBanners, getAllCategory } from '../../services/api';

function BasicBanners() {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [data, setData] = useState();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [category, setCategory] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchBanners = async () => {
    setLoading(true);
    const response = await getAllBanners();
    console.log('resss', response);
    if (response?.status === 200) {
      setBanners([...response?.data]);
    } else {
      toast(response?.data);
    }
    setLoading(false);
  };

  const onClickBanner = (row: any) => {
    setData(row);
    setIsEdit(true);
    setOpen(true);
  };

  const handleDelete = async (id: number) => {
    const response = await deleteBanner(id);
    if (response.status === 200) {
      toast('Banner deleted');
      
    }
  };

  const fetchAllCategory = async () => {
    const response = await getAllCategory();
    if (response?.status === 200) {
      console.log('Banner categoiry', response?.data)
      setCategory([...response.data]);
    } else {
      toast(response?.data);
    }
  };

  const filterCategory = (id:number | string) => {
    const data = category.filter((item) => item.cat_id == id);
    return data;
  }

  useEffect(() => {
    fetchBanners();
    fetchAllCategory();
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div style={{ padding: '20px' }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: 'white',
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          <Button
            sx={{
              backgroundColor: 'rgba(252, 128, 25, 1)',
              color: 'white',
              border: 'none',
              '&:hover': {
                backgroundColor: 'rgba(252, 128, 25, 1)',
                color: 'white',
                border: 'none',
              },
              textTransform: 'none',
              whiteSpace: 'nowrap',
            }}
            onClick={() => {
              setIsEdit(false);
              setOpen(true);
              setData(null);
            }}
          >
            Add New Banner
          </Button>
        </Toolbar>
        <div
          style={{
            border: '1px solid #ccc',
            borderRadius: '10px',
            marginTop: '20px',
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Image
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Navigate To
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banners &&
                  banners.map((row, index) => (
                    <TableRow
                      onClick={() => {}}
                      key={index}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        style={{
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        <img src={row?.image} alt='' width={60} height={40} />
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: '1px solid #ccc',
                          fontSize: '13px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          color: '#848684',
                        }}
                      >
                        {filterCategory(row?.navigate_to)?.[0]?.name || row?.navigate_to}
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: '1px solid #ccc',
                          fontSize: '13px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          color: '#848684',
                        }}
                      >
                        <ModalButton
                          title={'Delete'}
                          color={'error.main'}
                          disabled={false}
                          onClick={() => handleDelete(row?.banner_id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {open && (
        <BasicModalBanner
          data={data}
          open={open}
          setOpen={setOpen}
          isEdit={isEdit}
          fetchBanners={fetchBanners}
        />
      )}
      <ToastContainer />
    </>
  );
}

export default BasicBanners;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  border: 'none',
  borderRadius: 0,
  width: '90%',
  maxWidth: '600px',
};

const BasicModalBanner = ({ open, setOpen, data, isEdit, fetchBanners }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [navigateTo, setNavigateTo] = useState(null);
  const [category, setCategory] = useState([]);

  const handSubmit = async () => {
    const formData = new FormData();
    navigateTo && formData.append('navigate_to', navigateTo);
    selectedFile && formData.append('banner_image', selectedFile);
    const response = await createBanner(formData);
    if (response?.status === 201) {
      toast('Banner added');
      fetchBanners();
      handleCloseModal();
    } else{ 
      toast(response?.data?.message || "Could not add banner")
    }
  };

  const handleCloseModal = () => {
    setSelectedFile(null);
    setNavigateTo(null);
    setOpen(false);
  };
  const fetchAllCategory = async () => {
    const response = await getAllCategory();
    if (response?.status === 200) {
      setCategory([...response.data]);
    } else {
      toast(response?.data);
    }
  };

  const handleDropdown = (e: any) => {
    setNavigateTo(parseInt(e));
  };

  useEffect(() => {
    fetchAllCategory()
  }, []);
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      style={{ padding: '0px' }}
    >
      <Box sx={style}>
        <Stack direction='row' justifyContent='space-between'>
          <span style={{ color: '#3D4152', fontWeight: '600' }}>
            Add Banner
          </span>
          <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </span>
        </Stack>
        <form style={{ marginBottom: '10px' }}>
          <InputLabel
            htmlFor='item-name'
            sx={{ fontSize: '15px', marginBottom: '3px' }}
          >
            Navigate To
          </InputLabel>
          {/* <FormControl fullWidth>
            <TextField
              id='item-name'
              variant='outlined'
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={data && data?.name}
              onChange={(e) => setNavigateTo(e.target.value)}
              sx={{
                '& .MuiInputBase-input': {
                  paddingY: '7px',
                  paddingX: '12px',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#fc8019',
                    borderWidth: '1px',
                  },
                },
              }}
            />
          </FormControl> */}
           {category && category.length > 0 && (
            <CategoryDropdown
              onChange={handleDropdown}
              options={category}
              value={`${category['0'].cat_id}` || 0}
            />
          )}
        </form>
        <div>
          <div
            style={{
              color: '#666666',
              marginBottom: '10px',
              marginTop: '10px',
              fontSize: '15px',
            }}
          >
            {data ? 'Change Image' : 'Upload Image'}
          </div>
          <input
            type='file'
            onChange={(e) => setSelectedFile(e.target.files[0])}
            accept='.jpg, .jpeg, .png, .webp'
          />
        </div>
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: isEdit ? 'space-between' : 'flex-end',
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <ModalButton
              title={'Close'}
              color={'secondary.main'}
              disabled={false}
              onClick={handleCloseModal}
            />
            <ModalButton
              title={'Save Changes'}
              color={'primary.main'}
              disabled={false}
              onClick={handSubmit}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};
