import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import authReducers from '../reducers/auth.reducers';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";


const persistConfig = {
  key: 'root',
  storage,
  // Add any blacklist/whitelist configurations if needed
};

const rootReducers = combineReducers({
  authReducers,
});
const persistedReducers = persistReducer(persistConfig, rootReducers);
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: persistedReducers,
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
