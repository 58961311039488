import { FormControl, InputLabel, TextField } from '@mui/material';

interface props {
  title: string;
  onChange: (value: any) => void;
  type: string;
  name: string;
  value: any;
  disabled?: boolean;
}

export const InputBox = ({
  title,
  onChange,
  type,
  name,
  value,
  disabled,
}: props) => {
  return (
    <form style={{ marginBottom: '20px' }}>
      <InputLabel
        htmlFor={title}
        sx={{ fontSize: '15px', marginBottom: '3px' }}
      >
        {title}
      </InputLabel>
      <FormControl fullWidth>
        <TextField
          id={title}
          variant='outlined'
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled}
          type={type}
          name={name}
          value={value}
          sx={{
            '& .MuiInputBase-input': {
              paddingY: '7px',
              paddingX: '12px',
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#fc8019',
                borderWidth: '1px',
              },
            },
          }}
          onChange={onChange}
        />
      </FormControl>
    </form>
  );
};
