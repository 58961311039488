import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Stack, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { InputBox } from '../../components/InputBox/InputBox';
import { ModalButton } from '../../components/button/ModalButton';
import { CategoryDropdown } from '../../components/dropdown/DropDown';
import {
  createItem,
  createItemOptions,
  deleteItem,
  getAllCategory,
  updateItem,
  updateItemOptions,
  updateItemStatus,
} from '../../services/api';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  border: 'none',
  borderRadius: 0,
  width: '90%',
  maxWidth: '700px',
  maxHeight: '80%',
  overflowY: 'scroll',
};

type itemPayloadProps = {
  name: undefined | string;
  description: undefined | string;
  price: null | number;
  discountPrice: null | number;
  cat_id: null | number;
  isVeg: boolean;
  is_available?: boolean;
  is_free_item?:boolean;
};

type ItemOptionsType = {
  item_id?: number;
  name: undefined | string;
  description?: undefined | string;
  price: null | number;
  discountPrice: null | number;
  cat_id?: null | number;
  isVeg?: boolean;
  is_available?: boolean;
  parent_id?: number;
  is_option?: boolean;
};

export default function BasicModalMenu({
  open,
  setOpen,
  data,
  isEdit,
  fetchMenu,
}) {
  const [value, setValue] = useState('veg');
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isFreeItem, setIsFreeItem] = useState(false);
  const [itemPayload, setItemPayload] = useState<itemPayloadProps>({
    ...data,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [itemOptionsPayload, setItemOptionsPayload] = useState<
    ItemOptionsType[]
  >(data?.options || []);
  const [catId, setCatId] = useState(0);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedFile(file);
  };

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (e.target as HTMLInputElement).value;
    setValue(newValue);
    setItemPayload((prevState) => ({
      ...prevState,
      isVeg: newValue === 'veg',
    }));
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setItemPayload((prevState) => ({
      ...prevState,
      [name]: name === 'name' || 'description' ? value : parseInt(value),
    }));
  };

  const fetchAllCategory = async () => {
    setLoading(true);
    const response = await getAllCategory();
    if (response?.status === 200) {
      setCategory([...response.data]);
    } else {
      toast(response?.data);
    }
    setLoading(false);
  };

  const handleDropdown = (e: any) => {
    setItemPayload((prevState) => ({
      ...prevState,
      cat_id: parseInt(e),
    }));
  };

  const handleClose = () => {
    setItemPayload({
      name: undefined,
      description: undefined,
      cat_id: null,
      discountPrice: null,
      price: null,
      isVeg: true,
    });
    setOpen(false);
  };

  const handleSubmit = async () => {
    const discountPrice = isFreeItem ? "0" : itemPayload.discountPrice as unknown as string; 
    const formData = new FormData();
    formData.append('name', itemPayload.name);
    formData.append('description', itemPayload.description);
    formData.append('price', itemPayload.price as unknown as string);
    formData.append(
      'discountPrice',
      discountPrice
    );
    formData.append('cat_id', itemPayload.cat_id as unknown as string);
    formData.append('isVeg', itemPayload?.isVeg as unknown as string);
    formData.append('is_free_item', itemPayload?.is_free_item as unknown as string);
    formData.append('item_image', selectedFile);
    if (data) {
      const response = await updateItem(formData, data?.item_id);
      if (response.status === 200) {
        toast('item updated successfully');
      }
      handleItemOptionsSubmit();
    } else {
      const response = await createItem(formData);
      if (response.status === 201) {
        toast('item created successfully');
        setOpen(false);
      } else{
        toast(response?.data?.message);
      }
    }
  };

  const handleItemOptionsSubmit = async () => {
    if (itemOptionsPayload.length > 0) {
      if (data?.options.length > 0) {
        const response = await updateItemOptions(
          itemOptionsPayload,
          data?.item_id
        );
        if (response.status === 200) {
          toast('Options updated successfully');
        } else {
          toast('Error updating options');
        }
      } else {
        const response = await createItemOptions(
          itemOptionsPayload,
          data?.item_id
        );
        if (response.status === 201) {
          toast('Options added successfully');
        } else {
          toast('Error creating options');
        }
      }
    }
  };

  const validateItemPayload = (): boolean => {
    for (const key in itemPayload) {
      if (itemPayload[key] === null || itemPayload[key] === undefined) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setCatId(data?.cat_id);
    setIsFreeItem(data?.is_free_item || false);
    setItemPayload((prevState) => ({
      ...prevState,
      cat_id: data?.cat_id,
      is_free_item: data?.is_free_item
    }));
  }, [data]);

  useEffect(() => {
    fetchAllCategory();
  }, []);

  useEffect(() => {
    setIsValid(validateItemPayload);
  }, [itemPayload]);

  const updateItemActiveStatus = async (item_id: number, status: boolean) => {
    const response = await updateItemStatus(item_id, status);
    if (response.status === 200) {
      toast('item updated successfully');
      setOpen(false);
      fetchMenu();
    }
  };

  const handleAddOption = () => {
    const optionPayload: ItemOptionsType = {
      name: undefined,
      discountPrice: null,
      price: null,
      is_available: true,
    };
    setItemOptionsPayload((prev) => [...prev, optionPayload]);
  };

  const handleDeleteItem = async (itemId: number | string) => {
    if(itemId) {
      const response = await deleteItem(itemId);
      if(response?.status === 200) {
        toast(response?.data?.message || 'Item delete successfully');
      }else {
        toast("Couldn't delete item right now");
      }
      setOpen(false);
    }
   
  }

  const handleOptionInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const option = itemOptionsPayload && itemOptionsPayload[index];
    if (option) {
      const name = e.target.name;
      const value = e.target.value;
      setItemOptionsPayload((prevState) => {
        const updatedOptions = [...prevState];
        updatedOptions[index] = {
          ...updatedOptions[index],
          [name]: name === 'name' ? value : parseInt(value),
        };
        return updatedOptions;
      });
    }
  };

  const handleChangeFreeItem = (e) => {
    setIsFreeItem(!isFreeItem);
    setItemPayload((prevState) => ({
      ...prevState,
      is_free_item: e.target.checked
    }));
  }

  return (
    <>
      <ToastContainer />
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ padding: '0px' }}
      >
        <Box sx={style}>
          <Stack direction='row' justifyContent='space-between'>
            <Box display={'flex'} alignItems={'center'} gap={2}>
            <span style={{ color: '#3D4152', fontWeight: '600' }}>
              {isEdit ? 'Update Item' : 'Add Item'}
            </span>
            <Typography>Making as free item</Typography>
            <Switch
            checked={isFreeItem}
            onChange={handleChangeFreeItem}
            name='loading'
            color='primary'
          />
            </Box>
            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </span>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <RadioGroup
              row
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              defaultValue={itemPayload?.isVeg ? 'veg' : 'nonveg'}
              onChange={handleChangeRadio}
            >
              <FormControlLabel
                value={'nonveg'}
                control={
                  <Radio sx={{ '&.Mui-checked': { color: '#eb5757' } }} />
                }
                label='Non veg'
                sx={{ color: '#777777' }}
              />
              <FormControlLabel
                value='veg'
                control={
                  <Radio sx={{ '&.Mui-checked': { color: '#1fbf75' } }} />
                }
                label='Veg'
                sx={{ color: '#777777' }}
              />
            </RadioGroup>
          </div>
          <InputBox
            value={itemPayload?.name}
            title={'Item Title'}
            name={'name'}
            onChange={handleChangeInput}
            type={'text'}
          />
          <InputBox
            value={itemPayload?.description}
            title={'Item Description'}
            name={'description'}
            onChange={handleChangeInput}
            type={'text'}
          />
          <InputBox
            value={itemPayload?.price}
            title={'Full Price'}
            name={'price'}
            onChange={handleChangeInput}
            type={'number'}
          />
          <InputBox
            value={isFreeItem ? 0 : itemPayload?.discountPrice}
            name={'discountPrice'}
            onChange={handleChangeInput}
            type={'number'}
            title={'Discount Price'}
            disabled={isFreeItem}
          />
          <InputLabel sx={{ fontSize: '15px', marginBottom: '3px' }}>
            Category
          </InputLabel>
          {category && category.length > 0 && (
            <CategoryDropdown
              onChange={handleDropdown}
              options={category}
              value={catId}
            />
          )}
          <div>
            <div
              style={{
                color: '#666666',
                marginBottom: '10px',
                marginTop: '10px',
                fontSize: '15px',
              }}
            >
              Image Upload
            </div>
            <input type='file' onChange={handleFileChange} />
          </div>
          {itemOptionsPayload.length > 0 && (
            <Box
              sx={{
                marginTop: '1rem',
              }}
            >
              <Divider />
              <Box
                sx={{
                  marginTop: '1rem',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 700,
                  }}
                >
                  Item Options
                </Typography>
                <Box sx={{ marginTop: 2 }}>
                  {itemOptionsPayload &&
                    itemOptionsPayload.map((eachItemOption, index) => (
                      <Box sx={{ marginTop: 2 }} key={index}>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          spacing={2}
                        >
                          <InputBox
                            value={eachItemOption?.name}
                            title={'Item Title'}
                            name={'name'}
                            onChange={(e) => handleOptionInputChange(e, index)}
                            type={'text'}
                          />
                          <InputBox
                            value={eachItemOption?.price}
                            title={'Full Price'}
                            name={'price'}
                            onChange={(e) => handleOptionInputChange(e, index)}
                            type={'number'}
                          />
                          <InputBox
                            value={eachItemOption?.discountPrice}
                            name={'discountPrice'}
                            onChange={(e) => handleOptionInputChange(e, index)}
                            type={'number'}
                            title={'Discount Price'}
                          />
                          <ModalButton
                            title={
                              eachItemOption?.is_available
                                ? 'Deactivate'
                                : 'Activate'
                            }
                            color={eachItemOption?.is_available ? 'info.main' : 'error.main'}
                            disabled={false}
                            onClick={() =>
                              updateItemActiveStatus(
                                eachItemOption?.item_id,
                                eachItemOption?.is_available ? false : true
                              )
                            }
                          />
                           <ModalButton
                            title={
                             'Delete'
                            }
                            color={'error.main'}
                            disabled={false}
                            onClick={() =>
                              handleDeleteItem(eachItemOption?.item_id)
                            }
                          />
                        </Stack>
                        <Divider />
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: isEdit ? 'space-between' : 'flex-end',
            }}
          >
            {isEdit && (
              <Stack direction={'row'} spacing={2}>
                <ModalButton
                  title={itemPayload?.is_available ? 'Disable' : 'Enable'}
                  color={'error.main'}
                  disabled={false}
                  onClick={() =>
                    updateItemActiveStatus(
                      data?.item_id,
                      data?.is_available ? false : true
                    )
                  }
                />
                 <ModalButton
                  title={'Delete'}
                  color={'error.main'}
                  disabled={false}
                  onClick={() => {handleDeleteItem(data?.item_id)}}
                />
                {!isFreeItem && <ModalButton
                  title={'Add Option'}
                  color={'info.main'}
                  disabled={false}
                  onClick={() => handleAddOption()}
                />}     
              </Stack>
            )}
            <Stack direction={'row'} spacing={2}>
              <ModalButton
                disabled={false}
                color={'#851897'}
                onClick={handleClose}
                title={'Close'}
              />
              <ModalButton
                disabled={false}
                color={'#fc8019'}
                onClick={handleSubmit}
                title={'Save Changes'}
              />
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
