import { useNavigate } from "react-router-dom";
import AuthGuard from "./guardedRoutes";

interface RootGuardProps {
  children: any;
}

const RootGuard = ({ children }:RootGuardProps) => {
  return <AuthGuard>{children}</AuthGuard>;
};

export default RootGuard;
