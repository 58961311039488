import { connect } from 'socket.io-client';
import { storageKeys } from '../constants/storagekeys';

//  TODO: should be updated with admin user id
const profile = localStorage.getItem(storageKeys.profile);
export const socket = connect(
  `${process.env.REACT_APP_API_URL}?userId=${JSON.parse(profile)?.userId}`,
  {
    extraHeaders: {
      'ngrok-skip-browser-warning': 'true',
    },
  }
);
