import React, { useEffect, useState } from 'react';
import {
  Grid,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { totalEarnings, totalOrders } from '../../services/api';
import { Loading } from '../../components/loading/Loading';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

function HomeScreen({ data }) {
  const [loading, setLoading] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState({
    totalOrder: 0,
    totalEarning: 0,
    todayOrders: 0,
    todayEarning: 0,
  });

  const fetchDashboard = async () => {
    setLoading(true);
    const totalOrdersByToday = await totalOrders(true);
    const totalEarningByToday = await totalEarnings(true);
    const allOrders = await totalOrders(false);
    const allEarning = await totalEarnings(false);
    if (totalEarningByToday?.status === 200) {
      setDashboardDetails((prevState) => ({
        ...prevState,
        todayEarning: totalEarningByToday?.data?.total,
      }));
    }
    if (totalOrdersByToday?.status === 200) {
      setDashboardDetails((prevState) => ({
        ...prevState,
        todayOrders: totalOrdersByToday?.data?.total,
      }));
    }
    if (allOrders?.status === 200) {
      setDashboardDetails((prevState) => ({
        ...prevState,
        totalOrder: allOrders?.data?.total,
      }));
    }
    if (allEarning?.status === 200) {
      setDashboardDetails((prevState) => ({
        ...prevState,
        totalEarning: allEarning?.data?.total,
      }));
    }

    setLoading(false);
  };
  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <Container>
      {loading && <Loading loading={loading} />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Box
            sx={{
              backgroundColor: 'rgb(30, 136, 229)',
              borderRadius: '7px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingLeft: '20px',
            }}
          >
            <ListItem sx={{ padding: 0 }}>
              <ListItemAvatar
                sx={{
                  background: 'rgb(21, 101, 192)',
                  borderRadius: '5px',
                  minWidth: '0px',
                }}
              >
                <Avatar sx={{ background: 'rgb(21, 101, 192)' }}>
                  <CurrencyRupeeIcon />
                </Avatar>
              </ListItemAvatar>
            </ListItem>
            <List sx={{ padding: 0 }}>
              <ListItem sx={{ padding: 0 }}>
                <ListItemText
                  primary={dashboardDetails.totalEarning || 0}
                  primaryTypographyProps={{
                    component: 'h1',
                    variant: 'h4',
                    sx: { fontWeight: 600 },
                  }}
                  sx={{ color: 'white' }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component='span'
                        variant='subtitle2'
                        sx={{ color: 'rgb(144, 202, 249)', fontWeight: 600 }}
                      >
                        Total Earning
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Box
            sx={{
              backgroundColor: 'rgb(94, 53, 177)',
              borderRadius: '7px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingLeft: '20px',
              marginBottom: { xs: '20px', md: '0px' },
            }}
          >
            <ListItem sx={{ padding: 0 }}>
              <ListItemAvatar
                sx={{
                  background: 'rgb(69, 39, 160)',
                  borderRadius: '5px',
                  minWidth: '0px',
                }}
              >
                <Avatar sx={{ background: 'rgb(69, 39, 160)' }}>
                  <LocalMallIcon />
                </Avatar>
              </ListItemAvatar>
            </ListItem>
            <List sx={{ padding: 0 }}>
              <ListItem sx={{ padding: 0 }}>
                <ListItemText
                  primary={dashboardDetails.totalOrder || 0}
                  primaryTypographyProps={{
                    component: 'h1',
                    variant: 'h4',
                    sx: { fontWeight: 600 },
                  }}
                  sx={{ color: 'white' }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component='span'
                        variant='subtitle2'
                        sx={{ color: 'rgb(176, 157, 219)', fontWeight: 600 }}
                      >
                        Total Order
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Stack
            direction={{ xs: 'column', md: 'row', lg: 'column' }}
            spacing={2}
          >
            <Box
              sx={{
                backgroundColor: 'rgb(30, 136, 229)',
                padding: '0px',
                borderRadius: '7px',
                width: { xs: '100%', md: 'calc(50% - 10px)', lg: '100%' },
              }}
            >
              <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItem>
                  <ListItemAvatar
                    sx={{
                      background: 'rgb(21, 101, 192)',
                      minWidth: '0px',
                      borderRadius: '5px',
                    }}
                  >
                    <Avatar sx={{ background: 'rgb(21, 101, 192)' }}>
                      <CurrencyRupeeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={dashboardDetails.todayEarning || 0}
                    primaryTypographyProps={{ sx: { fontWeight: 700 } }}
                    sx={{
                      marginLeft: { xs: '20px', md: '30px' },
                      color: 'white',
                    }}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component='span'
                          variant='subtitle2'
                          sx={{ color: 'rgb(144, 202, 249)', fontWeight: 600 }}
                        >
                          Today's Earning
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Box>
            <Box
              sx={{
                backgroundColor: 'rgb(94, 53, 177)',
                padding: '0px',
                borderRadius: '7px',
                marginLeft: { xs: 0, md: '20px', lg: 0 },
                width: { xs: '100%', md: 'calc(50% - 10px)', lg: '100%' },
              }}
            >
              <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItem alignItems='center'>
                  <ListItemAvatar
                    sx={{
                      background: 'rgb(69, 39, 160)',
                      minWidth: '0px',
                      borderRadius: '5px',
                    }}
                  >
                    <Avatar sx={{ background: 'rgb(69, 39, 160)' }}>
                      <LocalMallIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={dashboardDetails.todayOrders || 0}
                    primaryTypographyProps={{ sx: { fontWeight: 700 } }}
                    sx={{
                      marginLeft: { xs: '20px', md: '30px' },
                      color: 'white',
                    }}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component='span'
                          variant='subtitle2'
                          sx={{ color: 'rgb(176, 157, 219)', fontWeight: 600 }}
                        >
                          Today's Orders
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomeScreen;
