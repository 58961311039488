import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import Editor from 'react-simple-wysiwyg';
import { toast } from 'react-toastify';
import { Loading } from '../../components/loading/Loading';
import {
  createOrUpdateSettings,
  getAdminProfile,
  getSettings,
} from '../../services/api';

function Settings() {
  const [userProfile, setUserProfile] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [settingsPayload, setSettingsPayload] = useState({
    settings_id: '',
    about_us: '',
    privacy_policy: '',
    terms_of_service: '',
    restaurant_name: '',
    restaurant_address: '',
    gst: null,
    platform_fee: null,
    delivery_charge: null,
    new_user_points: '',
    points_money_value: '',
    referral_points: ''
  });

  useEffect(() => {
    fetchUserDetails();
    fetchSettings();
  }, []);

  const fetchUserDetails = async () => {
    const result = await getAdminProfile();
    if (result?.status === 200) {
      setUserProfile(result?.data);
    }
  };

  const fetchSettings = async () => {
    const result = await getSettings();
    if (result?.status === 200) {
      setSettingsPayload((prev) => ({
        ...prev,
        ...result?.data,
      }));
    }
  };

  const handleOnClickSave = async () => {
    console.log("Page is wortkin")
    setLoading(true);
    const response = await createOrUpdateSettings(settingsPayload);
    if(response?.status === 201) {
      toast("Details Updated");
    }else{
      toast("Could not update details at this moment");
    }
    setLoading(false)
    console.log(response)
  };

  return (
    <>
    {loading && <Loading loading={loading} />}
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* Account Details */}
          <Container sx={{ marginBottom: 3 }}>
            <Box
              sx={{
                color: 'rgb(105, 117, 134)',
                display: 'flex',
                alignItems: 'center',
                marginBottom: 1,
              }}
            >
              <ErrorOutlineOutlinedIcon
                sx={{ fontSize: '1.5rem', marginRight: '5px' }}
              />
              <Typography variant='body1' sx={{ fontSize: '1.5rem' }}>
                Account Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  First Name
                </InputLabel>
                <TextField
                  disabled
                  id='first-name'
                  variant='outlined'
                  value={userProfile?.first_name || 'first name'}
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Last Name
                </InputLabel>
                <TextField
                  disabled
                  id='last-name'
                  variant='outlined'
                  value={userProfile?.last_name || 'last name'}
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Email
                </InputLabel>
                <TextField
                disabled
                  id='first-name'
                  variant='outlined'
                  value={userProfile?.email || 'email'}
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Mobile
                </InputLabel>
                <TextField
                disabled
                  id='last-name'
                  variant='outlined'
                  value={userProfile?.mobile || 'mobile number'}
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
            </Box>
          </Container>
          <Divider />
          {/* Platform Details */}
          <Container sx={{ marginBottom: 2, marginTop: 1 }}>
            <Box
              sx={{
                color: 'rgb(105, 117, 134)',
                display: 'flex',
                alignItems: 'center',
                marginBottom: 1,
              }}
            >
              <ErrorOutlineOutlinedIcon
                sx={{ fontSize: '1.5rem', marginRight: '5px' }}
              />
              <Typography variant='body1' sx={{ fontSize: '1.5rem' }}>
                Platform Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Gst
                </InputLabel>
                <TextField
                  id='first-name'
                  variant='outlined'
                  value={settingsPayload?.gst}
                  onChange={(e) =>
                    setSettingsPayload((prev) => ({
                      ...prev,
                      gst: e.target.value,
                    }))
                  }
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Platform Fee
                </InputLabel>
                <TextField
                  id='last-name'
                  variant='outlined'
                  value={settingsPayload?.platform_fee}
                  onChange={(e) =>
                    setSettingsPayload((prev) => ({
                      ...prev,
                      platform_fee: e.target.value,
                    }))
                  }
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
              
            </Box>


            <Box  sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
                marginBottom: 2,
              }}>
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Referral points of user
                </InputLabel>
                <TextField
                  id='last-name'
                  variant='outlined'
                  value={settingsPayload?.referral_points}
                  onChange={(e) =>
                    setSettingsPayload((prev) => ({
                      ...prev,
                      referral_points: e.target.value,
                    }))
                  }
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  New user points
                </InputLabel>
                <TextField
                  id='last-name'
                  variant='outlined'
                  value={settingsPayload?.new_user_points}
                  onChange={(e) =>
                    setSettingsPayload((prev) => ({
                      ...prev,
                      new_user_points: e.target.value,
                    }))
                  }
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
              
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Delivery Charge
                </InputLabel>
                <TextField
                  id='first-name'
                  variant='outlined'
                  value={settingsPayload?.delivery_charge}
                  onChange={(e) =>
                    setSettingsPayload((prev) => ({
                      ...prev,
                      delivery_charge: e.target.value,
                    }))
                  }
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Restaurant Name
                </InputLabel>
                <TextField
                  id='first-name'
                  variant='outlined'
                  value={settingsPayload?.restaurant_name}
                  onChange={(e) =>
                    setSettingsPayload((prev) => ({
                      ...prev,
                      restaurant_name: e.target.value,
                    }))
                  }
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor='item-name'
                  sx={{ fontSize: '15px', marginBottom: '3px' }}
                >
                  Restaurant Address
                </InputLabel>
                <TextField
                  id='last-name'
                  variant='outlined'
                  value={settingsPayload?.restaurant_address}
                  onChange={(e) =>
                    setSettingsPayload((prev) => ({
                      ...prev,
                      restaurant_address: e.target.value,
                    }))
                  }
                  sx={{
                    flex: 6,
                    '& input': {
                      paddingY: '7px',
                      paddingX: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#fc8019',
                        borderWidth: '1px',
                      },
                    },
                    width: '100%',
                  }}
                />
              </Box>
            </Box>
          </Container>
          <Divider />
          {/* Pages */}
          <Container sx={{ marginBottom: 2, marginTop: 1 }}>
            <Box
              sx={{
                color: 'rgb(105, 117, 134)',
                display: 'flex',
                alignItems: 'center',
                marginBottom: 1,
              }}
            >
              <ErrorOutlineOutlinedIcon
                sx={{ fontSize: '1.5rem', marginRight: '5px' }}
              />
              <Typography variant='body1' sx={{ fontSize: '1.5rem' }}>
                Pages
              </Typography>
            </Box>
            <Box sx={{ width: '100%', mb: 2 }}>
              <InputLabel
                htmlFor='item-name'
                sx={{ fontSize: '15px', marginBottom: '3px' }}
              >
                About Us
              </InputLabel>
              <Editor
                value={settingsPayload?.about_us}
                onChange={(e) =>
                  setSettingsPayload((prev) => ({
                    ...prev,
                    about_us: e.target.value,
                  }))
                }
              />
            </Box>
            <Box sx={{ width: '100%', mb: 2 }}>
              <InputLabel
                htmlFor='item-name'
                sx={{ fontSize: '15px', marginBottom: '3px' }}
              >
                Privacy Policy
              </InputLabel>
              <Editor
                value={settingsPayload?.privacy_policy}
                onChange={(e) =>
                  setSettingsPayload((prev) => ({
                    ...prev,
                    privacy_policy: e.target.value,
                  }))
                }
              />
            </Box>
            <Box sx={{ width: '100%', mb: 2 }}>
              <InputLabel
                htmlFor='item-name'
                sx={{ fontSize: '15px', marginBottom: '3px' }}
              >
                Terms Of Service
              </InputLabel>
              <Editor
                value={settingsPayload?.terms_of_service}
                onChange={(e) =>
                  setSettingsPayload((prev) => ({
                    ...prev,
                    terms_of_service: e.target.value,
                  }))
                }
              />
            </Box>
          </Container>
          <Stack direction={'row'} justifyContent={'flex-start'} sx={{ m: 3 }}>
            <Button onClick={handleOnClickSave} variant='contained'>
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default Settings;
