import ReactDOM from 'react-dom/client';
import './index.css';
import SplashScreen from './pages';
import { Provider } from 'react-redux';
import { store } from './redux/store/store';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { appTheme } from './themes/appTheme';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <SplashScreen />
        <ToastContainer containerId={'contA'} />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);
