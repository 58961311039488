import {
  Toolbar,
  Box,
  IconButton,
  TextField,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BasicModalCategory from './BasicModalCategory';
import { useEffect, useState } from 'react';
import { getAllCategory } from '../../services/api';
import { toast } from 'react-toastify';
import { Loading } from '../../components/loading/Loading';

function BasicCategory({ icons }) {
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [data, setData] = useState();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [open, setOpen] = useState(false);

  const fetchCategories = async () => {
    setLoading(true);
    const response = await getAllCategory();
    if (response?.status === 200) {
      setCategoryList([...response?.data]);
    } else {
      toast(response?.data);
    }
    setLoading(false);
  };

  const onClickCategory = (row: any) => {
    setData(row);
    setIsEdit(true);
    setOpen(true);
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      <Loading loading={loading} />
      <div style={{ padding: '20px' }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            paddingLeft: '0px',
            paddingRight: '0px',
            gap: '10px',
          }}
        >
          {/* <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: '5px',
            }}
          >
            <IconButton>
              <SearchIcon sx={{ color: 'rgba(252, 128, 25, 1)' }} />
            </IconButton>
            <TextField
              placeholder='Search here'
              variant='standard'
              InputProps={{ disableUnderline: true }}
              inputProps={{ style: { color: '#646465' } }}
            />
          </Box> */}
          <Box></Box>
          <Button
            sx={{
              backgroundColor: 'rgba(252, 128, 25, 1)',
              color: 'white',
              border: 'none',
              '&:hover': {
                backgroundColor: 'rgba(252, 128, 25, 1)',
                color: 'white',
                border: 'none',
              },
              textTransform: 'none',
              whiteSpace: 'nowrap',
            }}
            onClick={() => {
              setIsEdit(false);
              setOpen(true);
              setData(null);
            }}
          >
            Add New Category
          </Button>
        </Toolbar>
        <Grid container spacing={2} mt={3}>
          {categoryList.map((row, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                onClick={() => onClickCategory(row)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  padding: '30px 0px',
                }}
              >
                <img
                  src={row.imageUrl}
                  width={200}
                  height={100}
                  alt=''
                  style={{ borderRadius: 10 }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 3,
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }} color='#3D4152'>
                    {row.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: row.is_active ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {row.is_active ? 'Activate' : 'Deactivated'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
      {open && (
        <BasicModalCategory
          data={data}
          open={open}
          setOpen={setOpen}
          isEdit={isEdit}
          fetchCategories={fetchCategories}
        />
      )}
    </>
  );
}

export default BasicCategory;
