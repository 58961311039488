import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import StyleIcon from '@mui/icons-material/Style';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import BallotIcon from '@mui/icons-material/Ballot';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

export const menuItems = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <InsertChartIcon />,
    isMenu: true,
  },
  {
    title: 'Orders (today)',
    path: '/orders',
    icon: <RestaurantIcon />,
    isMenu: true,
  },
  {
    title: 'Order History',
    path: '/order-history',
    icon: <RestartAltIcon />,
    isMenu: false,
  },
  {
    title: 'Category',
    path: '/category',
    icon: <BallotIcon />,
    isMenu: true,
  },
  {
    title: 'Menu',
    path: '/menu',
    icon: <DinnerDiningIcon />,
    isMenu: true,
  },
  {
    title: 'Coupons',
    path: '/coupons',
    icon: <StyleIcon />,
    isMenu: true,
  },
  {
    title: 'Riders',
    path: '/riders',
    icon: <DeliveryDiningIcon />,
    isMenu: true,
  },
  {
    title: 'Users',
    path: '/users',
    icon: <SupervisedUserCircleIcon />,
    isMenu: true,
  },
  {
    title: 'Banners',
    path: '/banners',
    icon: <ViewDayIcon />,
    isMenu: true,
  },
  {
    title: 'settings',
    path: '/settings',
    icon: <SettingsIcon />,
    isMenu: true,
  },
];
