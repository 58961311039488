import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';

interface EachOrderProps {
  eachOrder: any;
  status?: string;
  setSelectedOrder?: any;
  setOpen?: any;
}

function EachOrder({
  eachOrder,
  status,
  setSelectedOrder,
  setOpen,
}: EachOrderProps) {
  function getColor(status): string {
    switch (status) {
      case 'new':
        return 'primary.main';
      case 'accepted':
        return 'success.main';
      case 'failed':
        return 'error.main';
      default:
        return 'text.secondary';
    }
  }

  return (
    <>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          transition: 'background-color 0.3s, border-color 0.3s',
          '&:hover': {
            backgroundColor: 'rgba(252, 128, 25, 0.1)',
            borderColor: 'rgba(252, 128, 25, 1)',
            '& button': {
              backgroundColor: 'rgba(252, 128, 25, 1) ',
              color: 'white ',
            },
          },
          cursor: 'pointer',
          //   mb: '1rem',
        }}
        onClick={() => {
          setOpen(true);
          setSelectedOrder(eachOrder);
        }}
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <div>
            <Typography
              variant='h5'
              component='div'
              style={{ fontSize: '16px' }}
            >
              Order # {eachOrder.orderNumber || 0}
            </Typography>
            <Typography color='text.secondary' style={{ fontSize: '13px' }}>
              Order Id {eachOrder?.order_id}
            </Typography>
          </div>
          <Typography
            variant='body2'
            component='p'
            sx={{
              '& strong': { color: 'rgba(252, 128, 25, 1)', display: 'block' },
            }}
          >
            <Typography style={{ fontSize: '18px' }} component='strong'>
              &#x20B9; {eachOrder.payment?.amount || 0}
            </Typography>
          </Typography>
          <Typography
            sx={{
              textTransform: 'capitalize',
              color: getColor(eachOrder.status || ''),
            }}
          >
            {eachOrder.status || ''}
          </Typography>
          <Typography
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {eachOrder?.payment?.paymentType || ''}
          </Typography>
          <Typography variant='body2' component='p'>
            <Typography style={{ fontSize: '14px' }} component='strong'>
              {moment(eachOrder.createdAt).format('YYYY-MM-DD HH:mm:ss A')}
            </Typography>
          </Typography>
          <Button
            variant='contained'
            endIcon={
              <ArrowForwardIosIcon
                sx={{
                  fontSize: '10px !important',
                  marginLeft: '-7px',
                  fontWeight: '10px',
                }}
              />
            }
            sx={{
              backgroundColor: '#f5f5f5',
              color: '#818799',
              padding: '7px !important',
              margin: '0 !important',
              minWidth: 0,
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default EachOrder;
