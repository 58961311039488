import { Button } from "@mui/material";
import React from "react";

export const ModalButton = ({ title, onClick, color, disabled }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: color,
        color: "white",
        border: "none",
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: color,
          color: "white",
          border: "none",
        },
        marginRight: "10px",
        textTransform: "capitalize",
        padding: "5px 24px",
        fontWeight: "500",
      }}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export const OutlinedButton = ({ title, onClick, disabled, Icon }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        color: "#000",
        border: '1px solid #fc8019',
        borderRadius: "8px",
        textTransform: "capitalize",
        padding: "5px 24px",
        fontWeight: "500",
      }}
      variant="outlined"
      endIcon={<Icon />}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};
