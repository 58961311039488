import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react'

const NoInternetConnetction = () => {
    const [isOnline, setOnline] = React.useState(true);

    React.useEffect(()=>{
        setOnline(navigator.onLine)
    }, [])
  return (
    <Box width={'100vw'} height={'100vh'} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <Box>
        <Typography sx={{fontWeight:'bold', fontSize:'8rem', letterSpacing:0.3, background: "linear-gradient(90deg, rgb(250 0 0) 8%, rgb(244 119 16 / 98%) 25%)", WebkitTextFillColor:'transparent', WebkitBackgroundClip:'text'}}>Opps !</Typography>
        <Typography my={2} sx={{textAlign:'center', fontWeight:'bold', color:'text.secondary'}}>Network Error - 404</Typography>
        <Typography my={2} sx={{textAlign:'center', fontWeight:'bold', color:'text.secondary'}}>Please check your internet connetction</Typography>
      </Box>
    </Box>
  )
}

export default NoInternetConnetction