import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../../assets/images/main_logo.png';
import { Loading } from '../../components/loading/Loading';
import { appRoutes } from '../../constants/routes';
import { loginAdmin, verifyOTP } from '../../services/api';
import { useDispatch } from 'react-redux';
import { setAuthDetails } from '../../redux/reducers/auth.reducers';
import { storageKeys } from '../../constants/storagekeys';
import { jwtDecode } from 'jwt-decode';

export default function LoginView() {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [mobile, setMobile] = useState<number>();
  const [isLogin, setIsLogin] = useState(true);
  const [otp, setOtp] = useState('');
  const [otpPayload, setOtpPayload] = useState({
    user_id: '',
    otp_id: 0,
    otp: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleClickLogin = async () => {
    if (!mobile) {
      alert('Please enter your mobile');
      return;
    }
    setIsLoading(true);
    const response = await loginAdmin({ mobile: Number(mobile) });
    if (response?.status === 201) {
      const resData = response?.data;
      setOtpPayload((prevState) => ({
        ...prevState,
        user_id: resData?.user_id,
        otp_id: resData?.otp_id,
        otp: 0,
      }));
    } else {
      toast('Error', response?.error);
    }
    setIsLoading(false);
    setIsLogin(false);
  };

  const handleClickOTP = async () => {
    setIsLoading(true);
    if (otpPayload && otpPayload !== undefined) {
      const response = await verifyOTP(otpPayload);
      if (response?.status === 201) {
        const accessToken = response?.data?.accessToken;
        if (accessToken) {
          dispatch(setAuthDetails(accessToken));
          localStorage.setItem(storageKeys.loginToken, accessToken);
          const decodedToken = jwtDecode(accessToken);
          localStorage.setItem(
            storageKeys.profile,
            JSON.stringify(decodedToken)
          );
          toast(response?.data?.message);
          navigation(appRoutes.dashboard);
        }
      } else {
        toast(response?.data?.message);
      }
    } else {
      toast('Please enter OTP');
    }
    setIsLoading(false);
  };

  const onChangeOtp = (val) => {
    setOtp(val);
    setOtpPayload((prevState) => ({
      ...prevState,
      otp: Number(val),
    }));
  };

  const handleMobile = (e: any) => {
    const mobileNumber = e.target.value;
    const mobileNumberPattern = /^\d{10}$/;
    if (mobileNumberPattern.test(mobileNumber)) {
      setMobile(mobileNumber);
    } else {
      toast('Invalid mobile number! Please enter a 10-digit number.');
    }
  };
  const renderFormLogin = (
    <>
      <Stack spacing={3}>
        <TextField
          name='mobile'
          label='Mobile number'
          fullWidth
          onChange={handleMobile}
          value={mobile}
        />
      </Stack>
      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        onClick={handleClickLogin}
        sx={{
          mt: 3,
          bgcolor: 'primary.main',
          color: '#ffffff',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        Sign Me In
      </LoadingButton>
    </>
  );

  const renderFormOTP = (
    <>
      <Stack
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MuiOtpInput value={otp} onChange={onChangeOtp} length={6} />
      </Stack>
      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        onClick={handleClickOTP}
        sx={{
          mt: 3,
          bgcolor: 'primary.main',
          color: '#ffffff',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        Validate
      </LoadingButton>
    </>
  );

  return (
    <Box
      sx={{
        backgroundColor: 'rgb(249, 250, 251)',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mx: { xs: 2, sm: 4 },
      }}
    >
      <Avatar
        src={logo}
        sx={{
          position: 'absolute',
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
        variant='square'
        alt='logo'
      />
      {isLoading && <Loading loading={isLoading} />}
      <Card
        sx={{
          p: 3,
          width: 1,
          maxWidth: 420,
        }}
      >
        <Typography my={3} variant='h4'>
          {isLogin ? 'Log In' : 'Otp Verify'}
        </Typography>
        {isLogin ? renderFormLogin : renderFormOTP}
      </Card>
    </Box>
  );
}
