import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Loading } from '../../components/loading/Loading';
import { deleteItem, getAllItems, searchMenuItems } from '../../services/api';
import BasicModalMenu from './BasicModalMenu';

function BasicMenu() {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [data, setData] = useState();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    if(query !== '') {
      searchMenu(query);
    } else {
      fetchAllMenu();
    }
    
  }

  const searchMenu = async (query:string) => {
    const response = await searchMenuItems(query);
    if(response?.status === 200) {
      setMenuItems(response?.data?.data);
    }
  }
  const fetchAllMenu = async () => {
    setLoading(true);
    const response = await getAllItems(rowsPerPage, page);
    if (response?.status === 200) {
      setTotalCount(response?.data?.total)
      setMenuItems([...response?.data?.data]);
    } else {
      toast(response?.data);
    }
    setLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setSearchQuery('');
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchQuery('');
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== row)
      );
    }
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedRows(menuItems);
    } else {
      setSelectedRows([]);
    }
  };

  const handleEdit = (row) => {
    if(!row?.is_deleted) {
      setData(row);
      setIsEdit(true);
      setOpen(true);
    }
  };
  useEffect(() => {
    fetchAllMenu();
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchAllMenu();
  }, [open]);

  return (
    <div style={{ padding: '20px' }}>
      <ToastContainer />
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          paddingLeft: '0px',
          paddingRight: '0px',
          gap: '10px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        >
          <IconButton>
            <SearchIcon sx={{ color: 'rgba(252, 128, 25, 1)' }} />
          </IconButton>
          <TextField
            placeholder='Search here'
            variant='standard'
            InputProps={{ disableUnderline: true }}
            inputProps={{ style: { color: '#646465' } }}
            value={searchQuery}
            onChange={handleSearchItems}
          />
        </Box>
        <Button
          sx={{
            backgroundColor: 'rgba(252, 128, 25, 1)',
            color: 'white',
            border: 'none',
            '&:hover': {
              backgroundColor: 'rgba(252, 128, 25, 1)',
              color: 'white',
              border: 'none',
            },
            textTransform: 'none',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            setOpen(true);
            setIsEdit(false);
            setData(null);
          }}
        >
          Add New Menu
        </Button>
      </Toolbar>
      <>
        <Loading loading={loading} />
        <div
          style={{
            border: '1px solid #ccc',
            borderRadius: '10px',
            marginTop: '20px',
          }}
        >
          <TableContainer>
            <Table sx={{minWidth: 1200}}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Menu
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Category
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Date
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Actual Price
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Discounted Price
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                    <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                      Status
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {menuItems.map((row, index) => (
                  <TableRow
                    onClick={() => handleEdit(row)}
                    key={index}
                    sx={{ cursor: 'pointer', 
                      backgroundColor: row?.is_deleted ? '#f9f7f7' : 'inherit',
                      pointerEvents: row?.is_deleted ? 'none' : 'auto',
                    }}
                  >
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <div>
                          <img
                            src={row.imageUrl}
                            alt=''
                            width={60}
                            height={40}
                          />
                        </div>
                        <Box sx={{ marginLeft: '1px', whiteSpace: 'nowrap' }}>
                          <Typography
                            style={{ fontSize: '16px' }}
                            fontWeight={600}
                          >
                            {row.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <Box sx={{ marginLeft: '1px', whiteSpace: 'nowrap' }}>
                          <Typography
                            style={{ fontSize: '16px' }}
                            fontWeight={600}
                          >
                            {row?.category?.name}
                          </Typography>
                        </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '100px',
                        color: '#848684',
                      }}
                    >
                      {moment(row.modified_time).format(
                        'YYYY-MM-DD HH:mm:ss A'
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <Typography
                        color={row.isVeg ? 'success.main' : 'error.main'}
                        style={{ fontSize: '13px' }}
                      >
                        {row.isVeg ? 'VEG' : 'NON VEG'}
                      </Typography>
                    </TableCell>

                    <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                      <Typography
                        sx={{
                          fontSize: '15px',
                          color: 'rgba(252, 128, 25, 1)',
                          flexShrink: 0,
                        }}
                        fontWeight={700}
                      >
                        {row.price}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                      <Typography
                        sx={{
                          fontSize: '15px',
                          color: 'rgba(252, 128, 25, 1)',
                          flexShrink: 0,
                        }}
                        fontWeight={700}
                      >
                        {row.discountPrice || row.price}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                      <Typography
                        sx={{
                          fontSize: '15px',
                          color: row?.is_deleted ? "error.main" : row.is_available ? 'success.main' : 'error.main',
                          flexShrink: 0,
                        }}
                        fontWeight={700}
                      >
                        {row.is_deleted ? 'Deleted' : row?.is_available ? "Available" : 'Not Available'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {searchQuery === '' && <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component='div'
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />}
          </TableContainer>
        </div>
      </>
      {open && (
        <BasicModalMenu
          fetchMenu={fetchAllMenu}
          open={open}
          data={data}
          isEdit={isEdit}
          setOpen={setOpen}
        />
      )}
    </div>
  );
}

export default BasicMenu;
