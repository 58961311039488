export const path = '';
export const routes = {
  login: '/login',
  privacyPolicy: "/privacy-policy",
  app: `/*`,
};
export const appRoutes = {
  dashboard: `${path}/dashboard`,
  orders: `${path}/orders`,
  orderHistory: `${path}/order-history`,
  menuItems: `${path}/menu`,
  category: `${path}/category`,
  coupons: `${path}/coupons`,
  settings: `${path}/settings`,
  riders: `${path}/riders`,
  users: `${path}/users`,
  banners: `${path}/banners`,
};
