import { Box, CircularProgress } from "@mui/material";

export const Loading = ({ loading }) => {
  if(!loading){
    return;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
        position: "fixed",
        top: "0px",
        left: "0px",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(2px)",
      }}
    >
      <CircularProgress sx={{ color: 'primary.main' }} />
    </Box>
  );
};
