import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { ModalButton } from '../../components/button/ModalButton';
import {
  createCategory,
  updateCategory,
  updateCategoryStatus,
} from '../../services/api';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  border: 'none',
  borderRadius: 0,
  width: '90%',
  maxWidth: '600px',
};

export default function BasicModalCategory({
  open,
  setOpen,
  data,
  isEdit,
  fetchCategories,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTitle(e.target.value);
  };

  const handSubmit = async () => {
    const formData = new FormData();
    title && formData.append('name', title);
    selectedFile && formData.append('category_image', selectedFile);
    if (data) {
      const response = await updateCategory(formData, data?.cat_id);
      if (response.status === 200) {
        toast('Category updated successfully');
        fetchCategories();
      }
    } else {
      const response = await createCategory(formData);
      if (response?.status === 201) {
        toast('Category added');
        fetchCategories();
      }
    }
    setOpen(false);
  };

  const updateCategoryActiveStatus = async (status: boolean) => {
    const cat_id = data && data?.cat_id;
    const response = await updateCategoryStatus(status, cat_id);
    if (response.status === 200) {
      toast('Category status updated successfully');
      fetchCategories();
    }
  };

  const handleCloseModal = () => {
    setSelectedFile(null);
    setTitle(null);
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ padding: '0px' }}
      >
        <Box sx={style}>
          <Stack direction='row' justifyContent='space-between'>
            <span style={{ color: '#3D4152', fontWeight: '600' }}>
              Add Category
            </span>
            <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </span>
          </Stack>
          <form style={{ marginBottom: '10px' }}>
            <InputLabel
              htmlFor='item-name'
              sx={{ fontSize: '15px', marginBottom: '3px' }}
            >
              Category Title
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                id='item-name'
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data && data?.name}
                onChange={(e) => handleInputChange(e)}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: '7px',
                    paddingX: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fc8019',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </FormControl>
          </form>
          <div>
            <div
              style={{
                color: '#666666',
                marginBottom: '10px',
                marginTop: '10px',
                fontSize: '15px',
              }}
            >
              {data ? 'Change Image' : 'Upload Image'}
            </div>
            <input
              type='file'
              onChange={handleFileChange}
              accept='.jpg, .jpeg, .png, .webp'
            />
          </div>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: isEdit ? 'space-between' : 'flex-end',
            }}
          >
            {isEdit && (
              <ModalButton
                title={data?.is_active ? 'Deactivate' : 'Activate'}
                color={'error.main'}
                disabled={false}
                onClick={() =>
                  updateCategoryActiveStatus(data?.is_active ? false : true)
                }
              />
            )}

            <Stack direction={'row'} spacing={2}>
              <ModalButton
                title={'Close'}
                color={'secondary.main'}
                disabled={false}
                onClick={handleCloseModal}
              />
              <ModalButton
                title={'Save Changes'}
                color={'primary.main'}
                disabled={false}
                onClick={handSubmit}
              />
            </Stack>
          </Box>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}
