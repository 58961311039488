/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import bellSound from '../../assets/old_telephone_ring.mp3';
import { Loading } from '../../components/loading/Loading';
import { getAllActiveRiders, getTodayOrders } from '../../services/api';
import BasicModalOrders from './BasicModalOrders';
import EachOrder from './EachOrder';
import React from 'react';

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'normal',
  '&:first-of-type': {
    textTransform: 'capitalize',
  },
  '&:not(.Mui-selected):hover': {
    color: 'rgba(252, 128, 25, 1) !important',
  },
  borderRadius: '10px',
}));

const Orders = ({ order, socket }) => {
  const [status, setStatus] = useState('new');
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [audio, setAudio] = useState({
    audio: new Audio(bellSound),
  });
  const [loading, setLoading] = useState(false);
  const [ordersList, setOrdersList] = useState([]);
  const [activeRiders, setActiveRiders] = React.useState([]);
  const [badgeCount, setBadgeCount] = React.useState({
    new: 0,
    preparing: 0,
    ready: 0,
    assigned: 0,
    delivered: 0,
    cancelled: 0,
  });

  const getActiveRiders = async () => {
    const response = await getAllActiveRiders();
    if (response.status === 200) {
      setActiveRiders(response?.data);
    }
  };

  const handleStatusChange = (event, newStatus: string) => {
    if (newStatus !== null) {
      fetchTodayOrder(newStatus);
      setStatus(newStatus);
    }
  };

  const playIt = () => {
    audio.audio.play();
    audio.audio.loop = true;
  };
  const stopIt = () => {
    audio.audio.pause();
    audio.audio.currentTime = 0;
  };

  useEffect(() => {
    getNewOrder();
  }, [socket]);

  useEffect(() => {
    fetchTodayOrder(status);
    fetchAllBadgeCounts();
  }, []);

  const fetchAllBadgeCounts = async () => {
    setLoading(true);
    try {
      const statuses = [
        'new',
        'preparing',
        'ready',
        'assigned',
        'delivered',
        'cancelled',
      ];
      const responses = await Promise.all(
        statuses.map((status) => getTodayOrders(status))
      );

      const badgeCounts = responses.reduce((acc, response, index) => {
        if (response?.status === 200) {
          acc[statuses[index]] = response?.data?.length || 0;
        } else {
          acc[statuses[index]] = 0; // Or handle the error as needed
        }
        return acc;
      }, {});

      setBadgeCount(badgeCounts);
    } catch (error) {
      console.error('Failed to fetch badge counts:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllBadgeCounts();
  }, [open, status]);

  const fetchTodayOrder = async (status: string) => {
    setLoading(true);
    const response = await getTodayOrders(status);
    console.log('Response', response);
    if (response?.status === 200) {
      setOrdersList(response?.data);
    } else {
      setOrdersList([]);
    }
    // get the active riders
    if (status === 'ready' || status === 'assigned') {
      getActiveRiders();
    }
    setLoading(false);
  };

  const getNewOrder = () => {
    socket.on('NEW_ORDER', (data: any) => {
      playIt();
      setOpen(true);
      setSelectedOrder(data?.data);
      console.log(data);
    });
    // return socket.off('NEW_ORDER');
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px',
      }}
    >
      {loading && <Loading loading={loading} />}
      <CardContent>
        <ToggleButtonGroup
          value={status}
          exclusive
          onChange={handleStatusChange}
          aria-label='order status buttons'
        >
          <CustomToggleButton
            value='new'
            style={{
              backgroundColor:
                status === 'new' ? 'rgba(252, 128, 25, 1)' : '#f5f5f5',
              color: status === 'new' ? 'white' : 'black',
            }}
          >
            New ({badgeCount.new})
          </CustomToggleButton>
          <CustomToggleButton
            value='preparing'
            style={{
              backgroundColor:
                status === 'preparing' ? 'rgba(252, 128, 25, 1)' : '#f5f5f5',
              color: status === 'preparing' ? 'white' : 'black',
            }}
          >
            Preparing ({badgeCount.preparing})
          </CustomToggleButton>
          <CustomToggleButton
            value='ready'
            style={{
              backgroundColor:
                status === 'ready' ? 'rgba(252, 128, 25, 1)' : '#f5f5f5',
              color: status === 'ready' ? 'white' : 'black',
            }}
          >
            Ready ({badgeCount.ready})
          </CustomToggleButton>
          <CustomToggleButton
            value='assigned'
            style={{
              backgroundColor:
                status === 'assigned' ? 'rgba(252, 128, 25, 1)' : '#f5f5f5',
              color: status === 'assigned' ? 'white' : 'black',
            }}
          >
            Rider Assigned ({badgeCount.assigned})
          </CustomToggleButton>
          <CustomToggleButton
            value='delivered'
            style={{
              backgroundColor:
                status === 'delivered' ? 'rgba(252, 128, 25, 1)' : '#f5f5f5',
              color: status === 'delivered' ? 'white' : 'black',
            }}
          >
            Completed ({badgeCount.delivered})
          </CustomToggleButton>
          <CustomToggleButton
            value='cancelled'
            style={{
              backgroundColor:
                status === 'cancelled' ? 'rgba(252, 128, 25, 1)' : '#f5f5f5',
              color: status === 'cancelled' ? 'white' : 'black',
            }}
          >
            Cancelled ({badgeCount.cancelled})
          </CustomToggleButton>
        </ToggleButtonGroup>

        <Box
          sx={{
            mt: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {ordersList.length > 0 &&
            ordersList.map((eachOrder, index) => (
              <EachOrder
                key={index}
                setOpen={setOpen}
                setSelectedOrder={setSelectedOrder}
                eachOrder={eachOrder}
                status={status}
              />
            ))}
        </Box>
      </CardContent>
      {open && (
        <BasicModalOrders
          playIt={playIt}
          stopIt={stopIt}
          order={selectedOrder}
          open={open}
          setOpen={setOpen}
          activeRiders={activeRiders}
          fetchTodayOrder={fetchTodayOrder}
          status={status}
        />
      )}
    </div>
  );
};

export default Orders;
