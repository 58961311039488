import { createTheme } from "@mui/material";
//Montserrat

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      success: string;
      danger: string;
      info: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      success?: string;
      danger?: string;
      info?: string;
    };
  }
}

export const appTheme = createTheme({
  palette:  {
    primary: {
      main: '#fc8019',
      contrastText: '#fff',
      light: 'rgb(101, 115, 195)',
    },
    error:{
      main:"#da4f49"
    },
    secondary:{
      main:"#851897"
    },
    success:{
      main: "#1BAD04"
    },
    
    divider: '#E2E6E9',
  },
  typography: {
    fontFamily: ["Archivo","Montserrat", "Roboto"].join(","),
    fontWeightRegular: 500,
  },
  status: {
    success: "#EEFFE9",
    danger: "#FFE9E9",
    info: "#607DE6",
  },
});
