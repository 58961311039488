/**
 * SplashScreen Component
 *
 * The `SplashScreen` component serves as the entry point of the application and sets up the initial routing and metadata for the app.
 * It uses React Router to handle different routes and displays the appropriate components based on the URL path.
 *
 * @returns {React.ReactNode} The `SplashScreen` component.
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { appRoutes, routes } from '../constants/routes';
import { RootState } from '../redux/store/store';
import AppScreen from './app';
import LoginScreen from './auth/LoginScreen';
import NoInternetConnetction from './app/NoInternetConnetction';
import PrivacyPolicy from './PrivacyPolicy';
const SplashScreen = () => {
  const [isOnline, setIsOnline] = useState(true);

  React.useEffect(() => {
    setIsOnline(navigator.onLine);
  }, []);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  /**
   * MetaDataWrapper Component
   *
   * The `MetaDataWrapper` component is a utility component that dynamically updates the document's metadata, such as title, favicon, and description,
   * based on the application's configuration (`appConfig`). It uses the `useEffect` hook to update the metadata once the component is mounted.
   *
   * @returns {React.ReactNode} The `MetaDataWrapper` component.
   */

  const MetaDataWrapper = () => {
    useEffect(() => {
      const changeMetaData = () => {
        document.title = 'Ashirwad Restaurant';
        const favicon: any = document.querySelector('link[rel="icon"]');
        if (favicon) {
          favicon.href = '';
        }
        const metaDescription = document.querySelector(
          'meta[name="description"]'
        );
        if (metaDescription) {
          metaDescription.setAttribute('content', 'Hotel & Restaurant');
        }
      };
      changeMetaData();
    }, []);

    return null;
  };

  const accessToken = useSelector(
    (state: RootState) => state.authReducers.accessToken
  );

  if (!isOnline) {
    return <NoInternetConnetction />;
  }

  return (
    <BrowserRouter>
  <MetaDataWrapper />
  <Routes>
    <Route
      path="/"
      element={accessToken ? <Navigate to={appRoutes.dashboard} replace /> : <LoginScreen />}
    />
    <Route
      path={routes.app}
      element={accessToken ? <AppScreen /> : <Navigate to={'/'} replace />}
    />
    <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
    <Route path="*" element={<Navigate to={'/'} replace />} />
  </Routes>
</BrowserRouter>
  );
};

export default SplashScreen;
