import {
  Box,
  alpha,
  Avatar,
  Typography,
  Stack,
  ListItemButton,
  Drawer,
  Button,
} from '@mui/material';
import { menuItems } from '../../constants/menuItems';
import { useResponsive } from '../../hooks/use-responsive';
import { NAV, HEADER } from '../../constants/config-layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import mainLogo from '../../assets/images/main_logo.png';
import { storageKeys } from '../../constants/storagekeys';

const profile = localStorage.getItem(storageKeys.profile);
const SideBar = ({ openNav, onCloseNav }) => {
  const { pathname } = useLocation();
  const upLg = useResponsive({
    query: 'up',
    start: 'lg',
  });

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}
    >
      <Avatar alt='photoURL' />
      <Box sx={{ ml: 2 }}>
        <Typography variant='subtitle2'>
          {' '}
          {JSON.parse(profile)?.role}
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {JSON.parse(profile)?.name}
        </Typography>
      </Box>
    </Box>
  );

  const renderMenu = (
    <Stack component='nav' spacing={0.5} sx={{ px: 2 }}>
      {menuItems.map((item) => (
        <NavItem key={item.title} item={item} />
      ))}
    </Stack>
  );

  const renderContent = (
    <Box
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        overflowY: 'auto',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            py: 1,
            px: 2,
            gap: 1,
            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            borderBottomStyle: 'dashed',
          }}
        >
          <Avatar
            variant='square'
            src={mainLogo}
            sx={{ width: 56, height: 56 }}
          />
          {/* <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Ashirwad Restaurant
          </Typography> */}
        </Box>
      </Box>

      {renderAccount}
      {renderMenu}
    </Box>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
        backgroundColor: 'rgb(249, 250, 251)',
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

function NavItem({ item }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const active = item.path === pathname;

  const handleMenuItemClick = (item) => {
    navigate(item.path);
  };

  return (
    <ListItemButton
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
      onClick={() => handleMenuItemClick(item)}
    >
      <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component='span'>{item.title} </Box>
    </ListItemButton>
  );
}

export default SideBar;
