import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/loading/Loading';
import SearchIcon from '@mui/icons-material/Search';
import BasicRiderModal from '../../components/BasicRiderModal';
import { getAllRiders, updateUserStatus } from '../../services/api';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BasicRiderOrdersModal from '../../components/BasicRiderOrdersModal';

export const BasicRiders = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [ordersModalOpen, setOrdersModalOpen] = useState(false);
  const [selectedRider, setSelectedRider] = useState<any>({});
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedRiderOrders, setSelectedRiderOrders] = useState([]);

  const handleThreeDotsOpen = (event: { currentTarget: any }) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setMenuAnchor(null);
  };

  useEffect(() => {
    fetchRiders();
  }, []);

  const handleDelete = () => {
    // Handle delete action
    handleThreeDotsClose();
  };

  const fetchRiders = async () => {
    const response = await getAllRiders();
    if (response?.status === 200) {
      setData(response?.data);
    }
  };

  const onClickRow = (event, rider: any) => {
    setSelectedRider(rider);
    handleThreeDotsOpen(event);
  };

  const handleClickOrders = () => {
    setSelectedRiderOrders(selectedRider?.rider_orders);
    setOrdersModalOpen(true);
    handleThreeDotsClose();
  };

  const handleUpdateStatus = async (status: string) => {
    const userId = selectedRider?.user?.user_id;
    let newStatus = status === 'block' ? 'block' : 'active';
    const response = await updateUserStatus({
      status: newStatus,
      userId,
    });
    fetchRiders();
  };

  return (
    <Container>
      {loading && <Loading loading={loading} />}
      <div>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: 'white',
            paddingLeft: '0px',
            paddingRight: '0px',
            gap: '10px',
          }}
        >
          <Box>
            <Button
              sx={{
                backgroundColor: 'rgba(252, 128, 25, 1)',
                color: 'white',
                border: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(252, 128, 25, 1)',
                  color: 'white',
                  border: 'none',
                },
                textTransform: 'none',
                whiteSpace: 'nowrap',
              }}
              onClick={() => setOpen(true)}
            >
              Add New Rider
            </Button>
          </Box>
        </Toolbar>
      </div>
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '10px',
          marginTop: '20px',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Mobile
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Available Status
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Order's
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ borderBottom: '1px solid #ccc', paddingLeft: 0 }}
                >
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.length > 0 &&
                data.map((eachRider, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        {eachRider?.user?.first_name}{' '}
                        {eachRider?.user?.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        {eachRider?.user?.mobile}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '13px',
                          color:
                            eachRider?.available_status === 'online'
                              ? 'green'
                              : 'red',
                          fontWeight: 700,
                        }}
                      >
                        {eachRider?.available_status?.toUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: '13px',
                          color:
                            eachRider?.available_status === 'online'
                              ? 'green'
                              : 'red',
                          fontWeight: 700,
                        }}
                      >
                        {eachRider?.user?.user_status?.toUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        {(eachRider?.rider_orders &&
                          eachRider?.rider_orders?.length) ||
                          0}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '100px',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <Box>
                        <IconButton onClick={(e) => onClickRow(e, eachRider)}>
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchor}
                          open={Boolean(menuAnchor)}
                          onClose={handleThreeDotsClose}
                          key={eachRider?.user?.user_id}
                        >
                          <MenuItem onClick={() => handleUpdateStatus('block')}>
                            Block
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleUpdateStatus('active')}
                          >
                            UnBlock
                          </MenuItem>
                          <MenuItem onClick={() => handleClickOrders()}>
                            View Orders
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <BasicRiderModal
        data={selectedRider}
        open={open}
        setOpen={setOpen}
        isEdit={isEdit}
      />
      <BasicRiderOrdersModal
        data={selectedRiderOrders}
        open={ordersModalOpen}
        setOpen={setOrdersModalOpen}
      />
    </Container>
  );
};
