import axios from 'axios';

import { storageKeys } from '../constants/storagekeys';
import { error } from 'console';

export const axiosinstance = axios.create({
  // baseURL: 'https://ashirwad-restaurent-api.ashirwadfood.in/',
  baseURL: process.env.REACT_APP_API_URL,
});

const addAuthTokenInterceptor = async () => {
  axiosinstance.interceptors.request.use(
    async (config) => {
      let loginToken = localStorage.getItem(storageKeys.loginToken) as string;

      config.headers.Authorization = `Bearer ${loginToken}`;
      config.headers['x-api-key'] = '12345sadsa6789asd90';
      config.headers['ngrok-skip-browser-warning'] = true;
      return config;
    },
    (error) => {
      throw error;
    }
  );
};

addAuthTokenInterceptor();
