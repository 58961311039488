import { Select, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const DropDown = ({ value, onChange, options }) => {
  return (
    <Select value={value} onChange={onChange} sx={{ padding: 0 }}>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export const CategoryDropdown = ({ value, onChange, options }) => {
  const [initVal, setInitVal] = useState<number>(value);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInitVal(value);
    onChange(value);
  };

  useEffect(() => {
    setInitVal(value);
  }, [value]);
  return (
    <Select
      value={initVal}
      onChange={handleChange}
      sx={{ padding: 0, width: '100%' }}
    >
      {options.map((option) => (
        <MenuItem key={option.cat_id} value={option.cat_id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};
