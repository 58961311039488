import { Route, Routes } from 'react-router-dom';
import RootGuard from '../../components/routes/routeGuard';
import { appRoutes, routes } from '../../constants/routes';
import AppScreenLayout from '../../components/appScreenLayout/AppScreenLayout';
import HomeScreen from './HomeScreen';
import Orders from './Orders';
import OrderHistory from './OrderHistory';
import BasicMenu from './BasicMenu';
import BasicCategory from './BasicCategory';
import BasicCoupons from './BasicCoupons';
import Settings from './Settings';
import { socket } from '../../utils/socket';
import { BasicRiders } from './BasicRiders';
import BasicBanners from './BasicBanners';
import { BasicUsers } from './BasicUsers';

const AppScreen = () => {
  const order = [
    {
      orderNo: 'Order #1',
      time: 'June 1, 2020, 08:22 AM',
      amount: '$202.00',
    },
    {
      orderNo: 'Order #2',
      time: 'June 1, 2020, 08:22 AM',
      amount: '$102.00',
    },
    {
      orderNo: 'Order #3',
      time: 'June 1, 2020, 08:22 AM',
      amount: '$532.00',
    },
  ];

  const data = [
    {
      Menu: 'fish',
      Date: 'June 1, 2020, 08:22 AM',
      Address: 'Elm Street, 23 Yogyakarta 2,97 Km',
      Total: '$5.59',
      Status: 'Completed',
    },
    {
      Menu: 'fish',
      Date: 'June 1, 2020, 08:22 AM',
      Address: 'Elm Street, 23 Yogyakarta 2,97 Km',
      Total: '$5.59',
      Status: 'Delivering',
    },
    {
      Menu: 'fish',
      Date: 'June 1, 2020, 08:22 AM',
      Address: 'Elm Street, 23 Yogyakarta 2,97 Km',
      Total: '$5.59',
      Status: 'Canceled',
    },
  ];

  const icons = [
    {
      icon: (
        <svg
          width='55'
          height='55'
          viewBox='0 0 50 50'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M28.3333 43.5937C20 47.2396 10.2604 43.4375 6.61457 35.1042C2.96874 26.7708 6.77082 
      17.0312 15.1042 13.3854C19.2708 11.5625 23.8021 11.6146 27.7083 13.125L29.4271 8.69791C24.375 6.71875 18.5417 6.66666 13.1771 9.01041C2.4479 13.6979 -2.44793 26.25 2.23957 36.9792C6.92707 
      47.7083 19.4792 52.6042 30.2083 47.9167C40.9375 43.2292 45.8333 30.6771 41.1458 19.9479L36.8229 21.875C40.4687 30.2083 36.6667 39.9479 28.3333 43.5937Z'
            fill='#FC8019'
          ></path>
          <path
            d='M34.6875 6.04168C38.6458 7.55209 41.9792 10.625 43.8021 14.7917L48.125 12.8646C45.7813 7.50001 41.5104 3.59376 36.4063 1.61459L34.6875 6.04168Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M43.2812 16.1458L42.8646 15.2083C41.1979 11.4062 38.1771 8.54165 34.3229 7.03124L33.3333 6.61457L35.8333 0.260406L36.8229 0.62499C42.3437 2.81249 46.7187 6.97915 49.1146
           12.4479L49.5312 13.3854L43.2812 16.1458ZM36.0416 5.46874C39.6354 7.1354 42.5 9.89582 44.3229 13.4375L46.7708 12.3958C44.6875 8.22915 41.3021 
           4.94791 37.0312 3.02082L36.0416 5.46874Z'
            fill='#3D4152'
          ></path>
          <path
            d='M43.8021 14.7917C41.9792 10.625 38.6459 7.60416 34.6875 6.04166L28.6979 21.4062L43.8021 14.7917Z'
            fill='#FC8019'
          ></path>
          <path
            d='M26.8229 23.3854L34.1146 4.6875L35.1042 5.05208C39.4792 6.77083 42.9167 10.0521 44.7917 14.375L45.2084 15.3125L26.8229 23.3854ZM35.2604 7.39583L30.5729 19.4271L42.3959 14.2708C40.8334 11.25 38.3334 8.85417 35.2604 7.39583Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.7188 46.0417C14.9479 46.0417 8.4896 42.1354 5.62501 35.5208C1.71876 26.6666 5.78126 16.3021 14.6354 12.3958C18.9063 10.5208 23.6979 10.4167 28.0729 12.1354L29.0625 12.5L23.5938 26.5104L37.3438 20.4687L37.7604 21.4062C39.6354 25.6771 39.7396 30.4687 38.0209 34.8437C36.3021 39.2187 33.0209 42.6562 28.6979 44.5312C26.4583 45.5729 24.1146 46.0417 21.7188 46.0417ZM7.5521 34.6875C10.9896 42.5 20.1042 46.0416 27.9167 42.6562C31.7188 40.9896 34.5834 37.9687 36.0938 34.1146C37.4479 30.5729 37.5 26.7708 36.25 23.2812L19.8438 30.4687L26.3542 13.75C22.8125 12.6042 18.9583 12.8125 15.5208 14.3229C7.70835 17.7604 4.16668 26.875 7.5521 34.6875Z'
            fill='#3D4152'
          ></path>
          <path
            d='M15.9896 39.7396C17.4278 39.7396 18.5938 38.5737 18.5938 37.1354C18.5938 35.6972 17.4278 34.5312 15.9896 34.5312C14.5514 34.5312 13.3854 35.6972 13.3854 37.1354C13.3854 38.5737 14.5514 39.7396 15.9896 39.7396Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M15.9895 40.7813C14.5833 40.7813 13.2291 39.9479 12.6041 38.5937C12.1875 37.7083 12.1875 36.7187 12.552 35.7812C12.9166 34.8437 13.5937 34.1667 14.4791 33.75C15.3645 33.3333 16.3541 33.3333 17.2916 33.6979C18.2291 34.0625 18.9062 34.7396 19.3229 35.625C19.7395 36.5104 19.7395 37.5 19.375 38.4375C19.0104 39.375 18.3333 40.0521 17.4479 40.4687C16.9791 40.6771 16.4583 40.7813 15.9895 40.7813ZM15.9895 35.5208C15.7812 35.5208 15.5729 35.5729 15.3645 35.6771C15 35.8333 14.6875 36.1458 14.5312 36.5625C14.375 36.9792 14.375 37.3958 14.5312 37.7604C14.8958 38.5417 15.8333 38.9062 16.6145 38.5937C16.9791 38.4375 17.2916 38.125 17.4479 37.7083C17.6041 37.2917 17.6041 36.875 17.4479 36.5104C17.2916 36.1458 16.9791 35.8333 16.5625 35.6771C16.3541 35.5729 16.1458 35.5208 15.9895 35.5208Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2709 23.3333C15.5365 23.3333 16.5625 22.3073 16.5625 21.0417C16.5625 
                  19.776 15.5365 18.75 14.2709 18.75C13.0052 18.75 11.9792 19.776 11.9792 21.0417C11.9792 22.3073 13.0052 23.3333 14.2709 23.3333Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2708 24.375C13.8542 24.375 13.4375 24.3229 13.0729 24.1667C12.2396 23.8542 11.6146 23.2292 11.25 22.3958C10.8854 21.5625 10.8854 20.6771 11.1979 19.8438C11.5104 19.0104 12.1354 18.3854 12.9687 18.0208C14.6354 17.2917 16.6146 18.0729 17.3437 19.7396C18.0729 21.4063 17.2917 23.3854 15.625 24.1146C15.2083 24.2708 14.7396 24.375 14.2708 24.375ZM14.2708 19.8438C14.1146 19.8438 13.9583 19.8958 13.8021 19.9479C13.4896 20.1042 13.2812 20.3125 13.125 20.625C13.0208 20.9375 13.0208 21.25 13.125 21.5625C13.2292 21.875 13.4896 22.0833 13.8021 22.2396C14.1146 22.3438 14.4271 22.3438 14.7396 22.2396C15.3646 21.9792 15.625 21.25 15.3646 20.625C15.2083 20.1042 14.7396 19.8438 14.2708 19.8438Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M29.4271 39.1667C29.0104 39.1667 28.6458 39.1146 28.2292 38.9583C27.3958 38.6458 26.7708 38.0208 26.4062 37.2396L28.3333 36.4063C28.4375 36.7188 28.6979 36.9271 28.9583 37.0313C29.2708 37.1354 29.5833 37.1354 29.8438 37.0313C30.4167 36.7708 30.7292 36.0938 30.4688 35.4688L32.3958 34.6354C33.125 36.3021 32.3438 38.2292 30.7292 38.9583C30.3125 39.1146 29.8958 39.1667 29.4271 39.1667ZM36.1979 15.7292C34.9479 15.7292 33.75 15 33.1771 13.75L35.1042 12.9167C35.3646 13.4896 36.0417 13.8021 36.6667 13.5417C37.2396 13.2813 37.5521 12.6042 37.2917 11.9792L39.2188 11.1458C39.9479 12.8125 39.1667 14.7396 37.5521 15.4688C37.0833 15.6771 36.6667 15.7292 36.1979 15.7292Z'
            fill='#3D4152'
          ></path>
          <path
            d='M9.70388 29.8397L7.63013 29.6406L7.48081 31.1959L9.55456 31.395L9.70388 29.8397Z'
            fill='#3D4152'
          ></path>
          <path
            d='M12.0833 34.1146L10.0521 33.6979C10.1562 33.125 10.4166 32.5521 10.7812 32.0833L12.4479 33.3333C12.2396 33.5937 12.1354 33.8542 12.0833 34.1146ZM14.6875 29.5312L12.9687 28.3333C13.2291 27.9167 13.5937 27.6042 14.0104 27.2917L15.2083 29.0104C15 29.1146 14.8437 29.3229 14.6875 29.5312Z'
            fill='#3D4152'
          ></path>
          <path
            d='M20.6437 23.9917L18.7612 23.0992L18.2926 24.0875L20.1751 24.98L20.6437 23.9917Z'
            fill='#3D4152'
          ></path>
          <path
            d='M37.5399 9.7654L35.6562 8.87543L35.189 9.86436L37.0726 10.7543L37.5399 9.7654Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.2292 18.5417L21.1458 18.1771C21.25 17.6562 21.4583 17.1354 21.7708 16.7187L23.4896 17.9167C23.3333 18.0729 23.2812 18.3333 23.2292 18.5417ZM19.4271 17.1354L17.3958 16.5625C17.5521 16.0417 17.8125 15.5208 18.2292 15.1042L19.7396 16.5625C19.6354 16.7187 19.4792 16.9271 19.4271 17.1354ZM8.43749 
                  24.8437C8.22916 24.2708 8.22916 23.6458 8.54166 23.0729L10.4167 24.0625L8.43749 24.8437Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.0815 33.8223L20.2463 31.9137L19.4351 32.2687L20.2703 34.1772L21.0815 33.8223Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.3484 37.8959L22.5113 35.9881L21.7005 36.3439L22.5376 38.2517L23.3484 37.8959Z'
            fill='#3D4152'
          ></path>
          <path
            d='M22.2532 41.8217L21.8372 39.7803L20.5614 40.0404L20.9774 42.0817L22.2532 41.8217Z'
            fill='#3D4152'
          ></path>
          <path
            d='M28.0084 41.1457L26.8884 39.3891L25.4392 40.3131L26.5592 42.0697L28.0084 41.1457Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.9062 33.8021C23.9062 33.0729 24.2187 32.3959 24.7917 31.9792L26.0937 33.6459C26.0417 33.6459 26.0417 33.6979 26.0417 33.75L23.9062 33.8021Z'
            fill='#3D4152'
          ></path>
          <path
            d='M29.4826 28.8412L28.3087 27.1201L27.233 27.8538L28.4069 29.5749L29.4826 28.8412Z'
            fill='#3D4152'
          ></path>
          <path
            d='M30.3646 33.5417L29.7917 31.5104C29.9479 31.4583 30.1563 31.3542 30.2604 31.25L31.6146 32.8646C31.25 33.1771 30.8334 33.3854 30.3646 33.5417Z'
            fill='#3D4152'
          ></path>
          <path
            d='M35.2013 28.177L33.6899 26.7432L32.507 27.9901L34.0184 29.4239L35.2013 28.177Z'
            fill='#3D4152'
          ></path>
          <path
            d='M18.4758 29.8661L16.6481 28.8662L16.2981 29.5059L18.1258 30.5058L18.4758 29.8661Z'
            fill='#3D4152'
          ></path>
          <path
            d='M34.475 16.5408L32.6477 15.5402L32.2975 16.1797L34.1248 17.1804L34.475 16.5408Z'
            fill='#3D4152'
          ></path>
        </svg>
      ),
      name: 'pizza',
    },
    {
      icon: (
        <svg
          width='55'
          height='55'
          viewBox='0 0 50 50'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M28.3333 43.5937C20 47.2396 10.2604 43.4375 6.61457 35.1042C2.96874 26.7708 6.77082 
      17.0312 15.1042 13.3854C19.2708 11.5625 23.8021 11.6146 27.7083 13.125L29.4271 8.69791C24.375 6.71875 18.5417 6.66666 13.1771 9.01041C2.4479 13.6979 -2.44793 26.25 2.23957 36.9792C6.92707 
      47.7083 19.4792 52.6042 30.2083 47.9167C40.9375 43.2292 45.8333 30.6771 41.1458 19.9479L36.8229 21.875C40.4687 30.2083 36.6667 39.9479 28.3333 43.5937Z'
            fill='#FC8019'
          ></path>
          <path
            d='M34.6875 6.04168C38.6458 7.55209 41.9792 10.625 43.8021 14.7917L48.125 12.8646C45.7813 7.50001 41.5104 3.59376 36.4063 1.61459L34.6875 6.04168Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M43.2812 16.1458L42.8646 15.2083C41.1979 11.4062 38.1771 8.54165 34.3229 7.03124L33.3333 6.61457L35.8333 0.260406L36.8229 0.62499C42.3437 2.81249 46.7187 6.97915 49.1146
           12.4479L49.5312 13.3854L43.2812 16.1458ZM36.0416 5.46874C39.6354 7.1354 42.5 9.89582 44.3229 13.4375L46.7708 12.3958C44.6875 8.22915 41.3021 
           4.94791 37.0312 3.02082L36.0416 5.46874Z'
            fill='#3D4152'
          ></path>
          <path
            d='M43.8021 14.7917C41.9792 10.625 38.6459 7.60416 34.6875 6.04166L28.6979 21.4062L43.8021 14.7917Z'
            fill='#FC8019'
          ></path>
          <path
            d='M26.8229 23.3854L34.1146 4.6875L35.1042 5.05208C39.4792 6.77083 42.9167 10.0521 44.7917 14.375L45.2084 15.3125L26.8229 23.3854ZM35.2604 7.39583L30.5729 19.4271L42.3959 14.2708C40.8334 11.25 38.3334 8.85417 35.2604 7.39583Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.7188 46.0417C14.9479 46.0417 8.4896 42.1354 5.62501 35.5208C1.71876 26.6666 5.78126 16.3021 14.6354 12.3958C18.9063 10.5208 23.6979 10.4167 28.0729 12.1354L29.0625 12.5L23.5938 26.5104L37.3438 20.4687L37.7604 21.4062C39.6354 25.6771 39.7396 30.4687 38.0209 34.8437C36.3021 39.2187 33.0209 42.6562 28.6979 44.5312C26.4583 45.5729 24.1146 46.0417 21.7188 46.0417ZM7.5521 34.6875C10.9896 42.5 20.1042 46.0416 27.9167 42.6562C31.7188 40.9896 34.5834 37.9687 36.0938 34.1146C37.4479 30.5729 37.5 26.7708 36.25 23.2812L19.8438 30.4687L26.3542 13.75C22.8125 12.6042 18.9583 12.8125 15.5208 14.3229C7.70835 17.7604 4.16668 26.875 7.5521 34.6875Z'
            fill='#3D4152'
          ></path>
          <path
            d='M15.9896 39.7396C17.4278 39.7396 18.5938 38.5737 18.5938 37.1354C18.5938 35.6972 17.4278 34.5312 15.9896 34.5312C14.5514 34.5312 13.3854 35.6972 13.3854 37.1354C13.3854 38.5737 14.5514 39.7396 15.9896 39.7396Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M15.9895 40.7813C14.5833 40.7813 13.2291 39.9479 12.6041 38.5937C12.1875 37.7083 12.1875 36.7187 12.552 35.7812C12.9166 34.8437 13.5937 34.1667 14.4791 33.75C15.3645 33.3333 16.3541 33.3333 17.2916 33.6979C18.2291 34.0625 18.9062 34.7396 19.3229 35.625C19.7395 36.5104 19.7395 37.5 19.375 38.4375C19.0104 39.375 18.3333 40.0521 17.4479 40.4687C16.9791 40.6771 16.4583 40.7813 15.9895 40.7813ZM15.9895 35.5208C15.7812 35.5208 15.5729 35.5729 15.3645 35.6771C15 35.8333 14.6875 36.1458 14.5312 36.5625C14.375 36.9792 14.375 37.3958 14.5312 37.7604C14.8958 38.5417 15.8333 38.9062 16.6145 38.5937C16.9791 38.4375 17.2916 38.125 17.4479 37.7083C17.6041 37.2917 17.6041 36.875 17.4479 36.5104C17.2916 36.1458 16.9791 35.8333 16.5625 35.6771C16.3541 35.5729 16.1458 35.5208 15.9895 35.5208Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2709 23.3333C15.5365 23.3333 16.5625 22.3073 16.5625 21.0417C16.5625 
                  19.776 15.5365 18.75 14.2709 18.75C13.0052 18.75 11.9792 19.776 11.9792 21.0417C11.9792 22.3073 13.0052 23.3333 14.2709 23.3333Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2708 24.375C13.8542 24.375 13.4375 24.3229 13.0729 24.1667C12.2396 23.8542 11.6146 23.2292 11.25 22.3958C10.8854 21.5625 10.8854 20.6771 11.1979 19.8438C11.5104 19.0104 12.1354 18.3854 12.9687 18.0208C14.6354 17.2917 16.6146 18.0729 17.3437 19.7396C18.0729 21.4063 17.2917 23.3854 15.625 24.1146C15.2083 24.2708 14.7396 24.375 14.2708 24.375ZM14.2708 19.8438C14.1146 19.8438 13.9583 19.8958 13.8021 19.9479C13.4896 20.1042 13.2812 20.3125 13.125 20.625C13.0208 20.9375 13.0208 21.25 13.125 21.5625C13.2292 21.875 13.4896 22.0833 13.8021 22.2396C14.1146 22.3438 14.4271 22.3438 14.7396 22.2396C15.3646 21.9792 15.625 21.25 15.3646 20.625C15.2083 20.1042 14.7396 19.8438 14.2708 19.8438Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M29.4271 39.1667C29.0104 39.1667 28.6458 39.1146 28.2292 38.9583C27.3958 38.6458 26.7708 38.0208 26.4062 37.2396L28.3333 36.4063C28.4375 36.7188 28.6979 36.9271 28.9583 37.0313C29.2708 37.1354 29.5833 37.1354 29.8438 37.0313C30.4167 36.7708 30.7292 36.0938 30.4688 35.4688L32.3958 34.6354C33.125 36.3021 32.3438 38.2292 30.7292 38.9583C30.3125 39.1146 29.8958 39.1667 29.4271 39.1667ZM36.1979 15.7292C34.9479 15.7292 33.75 15 33.1771 13.75L35.1042 12.9167C35.3646 13.4896 36.0417 13.8021 36.6667 13.5417C37.2396 13.2813 37.5521 12.6042 37.2917 11.9792L39.2188 11.1458C39.9479 12.8125 39.1667 14.7396 37.5521 15.4688C37.0833 15.6771 36.6667 15.7292 36.1979 15.7292Z'
            fill='#3D4152'
          ></path>
          <path
            d='M9.70388 29.8397L7.63013 29.6406L7.48081 31.1959L9.55456 31.395L9.70388 29.8397Z'
            fill='#3D4152'
          ></path>
          <path
            d='M12.0833 34.1146L10.0521 33.6979C10.1562 33.125 10.4166 32.5521 10.7812 32.0833L12.4479 33.3333C12.2396 33.5937 12.1354 33.8542 12.0833 34.1146ZM14.6875 29.5312L12.9687 28.3333C13.2291 27.9167 13.5937 27.6042 14.0104 27.2917L15.2083 29.0104C15 29.1146 14.8437 29.3229 14.6875 29.5312Z'
            fill='#3D4152'
          ></path>
          <path
            d='M20.6437 23.9917L18.7612 23.0992L18.2926 24.0875L20.1751 24.98L20.6437 23.9917Z'
            fill='#3D4152'
          ></path>
          <path
            d='M37.5399 9.7654L35.6562 8.87543L35.189 9.86436L37.0726 10.7543L37.5399 9.7654Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.2292 18.5417L21.1458 18.1771C21.25 17.6562 21.4583 17.1354 21.7708 16.7187L23.4896 17.9167C23.3333 18.0729 23.2812 18.3333 23.2292 18.5417ZM19.4271 17.1354L17.3958 16.5625C17.5521 16.0417 17.8125 15.5208 18.2292 15.1042L19.7396 16.5625C19.6354 16.7187 19.4792 16.9271 19.4271 17.1354ZM8.43749 
                  24.8437C8.22916 24.2708 8.22916 23.6458 8.54166 23.0729L10.4167 24.0625L8.43749 24.8437Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.0815 33.8223L20.2463 31.9137L19.4351 32.2687L20.2703 34.1772L21.0815 33.8223Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.3484 37.8959L22.5113 35.9881L21.7005 36.3439L22.5376 38.2517L23.3484 37.8959Z'
            fill='#3D4152'
          ></path>
          <path
            d='M22.2532 41.8217L21.8372 39.7803L20.5614 40.0404L20.9774 42.0817L22.2532 41.8217Z'
            fill='#3D4152'
          ></path>
          <path
            d='M28.0084 41.1457L26.8884 39.3891L25.4392 40.3131L26.5592 42.0697L28.0084 41.1457Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.9062 33.8021C23.9062 33.0729 24.2187 32.3959 24.7917 31.9792L26.0937 33.6459C26.0417 33.6459 26.0417 33.6979 26.0417 33.75L23.9062 33.8021Z'
            fill='#3D4152'
          ></path>
          <path
            d='M29.4826 28.8412L28.3087 27.1201L27.233 27.8538L28.4069 29.5749L29.4826 28.8412Z'
            fill='#3D4152'
          ></path>
          <path
            d='M30.3646 33.5417L29.7917 31.5104C29.9479 31.4583 30.1563 31.3542 30.2604 31.25L31.6146 32.8646C31.25 33.1771 30.8334 33.3854 30.3646 33.5417Z'
            fill='#3D4152'
          ></path>
          <path
            d='M35.2013 28.177L33.6899 26.7432L32.507 27.9901L34.0184 29.4239L35.2013 28.177Z'
            fill='#3D4152'
          ></path>
          <path
            d='M18.4758 29.8661L16.6481 28.8662L16.2981 29.5059L18.1258 30.5058L18.4758 29.8661Z'
            fill='#3D4152'
          ></path>
          <path
            d='M34.475 16.5408L32.6477 15.5402L32.2975 16.1797L34.1248 17.1804L34.475 16.5408Z'
            fill='#3D4152'
          ></path>
        </svg>
      ),
      name: 'pizza',
    },
    {
      icon: (
        <svg
          width='55'
          height='55'
          viewBox='0 0 50 50'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M28.3333 43.5937C20 47.2396 10.2604 43.4375 6.61457 35.1042C2.96874 26.7708 6.77082 
      17.0312 15.1042 13.3854C19.2708 11.5625 23.8021 11.6146 27.7083 13.125L29.4271 8.69791C24.375 6.71875 18.5417 6.66666 13.1771 9.01041C2.4479 13.6979 -2.44793 26.25 2.23957 36.9792C6.92707 
      47.7083 19.4792 52.6042 30.2083 47.9167C40.9375 43.2292 45.8333 30.6771 41.1458 19.9479L36.8229 21.875C40.4687 30.2083 36.6667 39.9479 28.3333 43.5937Z'
            fill='#FC8019'
          ></path>
          <path
            d='M34.6875 6.04168C38.6458 7.55209 41.9792 10.625 43.8021 14.7917L48.125 12.8646C45.7813 7.50001 41.5104 3.59376 36.4063 1.61459L34.6875 6.04168Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M43.2812 16.1458L42.8646 15.2083C41.1979 11.4062 38.1771 8.54165 34.3229 7.03124L33.3333 6.61457L35.8333 0.260406L36.8229 0.62499C42.3437 2.81249 46.7187 6.97915 49.1146
           12.4479L49.5312 13.3854L43.2812 16.1458ZM36.0416 5.46874C39.6354 7.1354 42.5 9.89582 44.3229 13.4375L46.7708 12.3958C44.6875 8.22915 41.3021 
           4.94791 37.0312 3.02082L36.0416 5.46874Z'
            fill='#3D4152'
          ></path>
          <path
            d='M43.8021 14.7917C41.9792 10.625 38.6459 7.60416 34.6875 6.04166L28.6979 21.4062L43.8021 14.7917Z'
            fill='#FC8019'
          ></path>
          <path
            d='M26.8229 23.3854L34.1146 4.6875L35.1042 5.05208C39.4792 6.77083 42.9167 10.0521 44.7917 14.375L45.2084 15.3125L26.8229 23.3854ZM35.2604 7.39583L30.5729 19.4271L42.3959 14.2708C40.8334 11.25 38.3334 8.85417 35.2604 7.39583Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.7188 46.0417C14.9479 46.0417 8.4896 42.1354 5.62501 35.5208C1.71876 26.6666 5.78126 16.3021 14.6354 12.3958C18.9063 10.5208 23.6979 10.4167 28.0729 12.1354L29.0625 12.5L23.5938 26.5104L37.3438 20.4687L37.7604 21.4062C39.6354 25.6771 39.7396 30.4687 38.0209 34.8437C36.3021 39.2187 33.0209 42.6562 28.6979 44.5312C26.4583 45.5729 24.1146 46.0417 21.7188 46.0417ZM7.5521 34.6875C10.9896 42.5 20.1042 46.0416 27.9167 42.6562C31.7188 40.9896 34.5834 37.9687 36.0938 34.1146C37.4479 30.5729 37.5 26.7708 36.25 23.2812L19.8438 30.4687L26.3542 13.75C22.8125 12.6042 18.9583 12.8125 15.5208 14.3229C7.70835 17.7604 4.16668 26.875 7.5521 34.6875Z'
            fill='#3D4152'
          ></path>
          <path
            d='M15.9896 39.7396C17.4278 39.7396 18.5938 38.5737 18.5938 37.1354C18.5938 35.6972 17.4278 34.5312 15.9896 34.5312C14.5514 34.5312 13.3854 35.6972 13.3854 37.1354C13.3854 38.5737 14.5514 39.7396 15.9896 39.7396Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M15.9895 40.7813C14.5833 40.7813 13.2291 39.9479 12.6041 38.5937C12.1875 37.7083 12.1875 36.7187 12.552 35.7812C12.9166 34.8437 13.5937 34.1667 14.4791 33.75C15.3645 33.3333 16.3541 33.3333 17.2916 33.6979C18.2291 34.0625 18.9062 34.7396 19.3229 35.625C19.7395 36.5104 19.7395 37.5 19.375 38.4375C19.0104 39.375 18.3333 40.0521 17.4479 40.4687C16.9791 40.6771 16.4583 40.7813 15.9895 40.7813ZM15.9895 35.5208C15.7812 35.5208 15.5729 35.5729 15.3645 35.6771C15 35.8333 14.6875 36.1458 14.5312 36.5625C14.375 36.9792 14.375 37.3958 14.5312 37.7604C14.8958 38.5417 15.8333 38.9062 16.6145 38.5937C16.9791 38.4375 17.2916 38.125 17.4479 37.7083C17.6041 37.2917 17.6041 36.875 17.4479 36.5104C17.2916 36.1458 16.9791 35.8333 16.5625 35.6771C16.3541 35.5729 16.1458 35.5208 15.9895 35.5208Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2709 23.3333C15.5365 23.3333 16.5625 22.3073 16.5625 21.0417C16.5625 
                  19.776 15.5365 18.75 14.2709 18.75C13.0052 18.75 11.9792 19.776 11.9792 21.0417C11.9792 22.3073 13.0052 23.3333 14.2709 23.3333Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2708 24.375C13.8542 24.375 13.4375 24.3229 13.0729 24.1667C12.2396 23.8542 11.6146 23.2292 11.25 22.3958C10.8854 21.5625 10.8854 20.6771 11.1979 19.8438C11.5104 19.0104 12.1354 18.3854 12.9687 18.0208C14.6354 17.2917 16.6146 18.0729 17.3437 19.7396C18.0729 21.4063 17.2917 23.3854 15.625 24.1146C15.2083 24.2708 14.7396 24.375 14.2708 24.375ZM14.2708 19.8438C14.1146 19.8438 13.9583 19.8958 13.8021 19.9479C13.4896 20.1042 13.2812 20.3125 13.125 20.625C13.0208 20.9375 13.0208 21.25 13.125 21.5625C13.2292 21.875 13.4896 22.0833 13.8021 22.2396C14.1146 22.3438 14.4271 22.3438 14.7396 22.2396C15.3646 21.9792 15.625 21.25 15.3646 20.625C15.2083 20.1042 14.7396 19.8438 14.2708 19.8438Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M29.4271 39.1667C29.0104 39.1667 28.6458 39.1146 28.2292 38.9583C27.3958 38.6458 26.7708 38.0208 26.4062 37.2396L28.3333 36.4063C28.4375 36.7188 28.6979 36.9271 28.9583 37.0313C29.2708 37.1354 29.5833 37.1354 29.8438 37.0313C30.4167 36.7708 30.7292 36.0938 30.4688 35.4688L32.3958 34.6354C33.125 36.3021 32.3438 38.2292 30.7292 38.9583C30.3125 39.1146 29.8958 39.1667 29.4271 39.1667ZM36.1979 15.7292C34.9479 15.7292 33.75 15 33.1771 13.75L35.1042 12.9167C35.3646 13.4896 36.0417 13.8021 36.6667 13.5417C37.2396 13.2813 37.5521 12.6042 37.2917 11.9792L39.2188 11.1458C39.9479 12.8125 39.1667 14.7396 37.5521 15.4688C37.0833 15.6771 36.6667 15.7292 36.1979 15.7292Z'
            fill='#3D4152'
          ></path>
          <path
            d='M9.70388 29.8397L7.63013 29.6406L7.48081 31.1959L9.55456 31.395L9.70388 29.8397Z'
            fill='#3D4152'
          ></path>
          <path
            d='M12.0833 34.1146L10.0521 33.6979C10.1562 33.125 10.4166 32.5521 10.7812 32.0833L12.4479 33.3333C12.2396 33.5937 12.1354 33.8542 12.0833 34.1146ZM14.6875 29.5312L12.9687 28.3333C13.2291 27.9167 13.5937 27.6042 14.0104 27.2917L15.2083 29.0104C15 29.1146 14.8437 29.3229 14.6875 29.5312Z'
            fill='#3D4152'
          ></path>
          <path
            d='M20.6437 23.9917L18.7612 23.0992L18.2926 24.0875L20.1751 24.98L20.6437 23.9917Z'
            fill='#3D4152'
          ></path>
          <path
            d='M37.5399 9.7654L35.6562 8.87543L35.189 9.86436L37.0726 10.7543L37.5399 9.7654Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.2292 18.5417L21.1458 18.1771C21.25 17.6562 21.4583 17.1354 21.7708 16.7187L23.4896 17.9167C23.3333 18.0729 23.2812 18.3333 23.2292 18.5417ZM19.4271 17.1354L17.3958 16.5625C17.5521 16.0417 17.8125 15.5208 18.2292 15.1042L19.7396 16.5625C19.6354 16.7187 19.4792 16.9271 19.4271 17.1354ZM8.43749 
                  24.8437C8.22916 24.2708 8.22916 23.6458 8.54166 23.0729L10.4167 24.0625L8.43749 24.8437Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.0815 33.8223L20.2463 31.9137L19.4351 32.2687L20.2703 34.1772L21.0815 33.8223Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.3484 37.8959L22.5113 35.9881L21.7005 36.3439L22.5376 38.2517L23.3484 37.8959Z'
            fill='#3D4152'
          ></path>
          <path
            d='M22.2532 41.8217L21.8372 39.7803L20.5614 40.0404L20.9774 42.0817L22.2532 41.8217Z'
            fill='#3D4152'
          ></path>
          <path
            d='M28.0084 41.1457L26.8884 39.3891L25.4392 40.3131L26.5592 42.0697L28.0084 41.1457Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.9062 33.8021C23.9062 33.0729 24.2187 32.3959 24.7917 31.9792L26.0937 33.6459C26.0417 33.6459 26.0417 33.6979 26.0417 33.75L23.9062 33.8021Z'
            fill='#3D4152'
          ></path>
          <path
            d='M29.4826 28.8412L28.3087 27.1201L27.233 27.8538L28.4069 29.5749L29.4826 28.8412Z'
            fill='#3D4152'
          ></path>
          <path
            d='M30.3646 33.5417L29.7917 31.5104C29.9479 31.4583 30.1563 31.3542 30.2604 31.25L31.6146 32.8646C31.25 33.1771 30.8334 33.3854 30.3646 33.5417Z'
            fill='#3D4152'
          ></path>
          <path
            d='M35.2013 28.177L33.6899 26.7432L32.507 27.9901L34.0184 29.4239L35.2013 28.177Z'
            fill='#3D4152'
          ></path>
          <path
            d='M18.4758 29.8661L16.6481 28.8662L16.2981 29.5059L18.1258 30.5058L18.4758 29.8661Z'
            fill='#3D4152'
          ></path>
          <path
            d='M34.475 16.5408L32.6477 15.5402L32.2975 16.1797L34.1248 17.1804L34.475 16.5408Z'
            fill='#3D4152'
          ></path>
        </svg>
      ),
      name: 'pizza',
    },
    {
      icon: (
        <svg
          width='55'
          height='55'
          viewBox='0 0 50 50'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M28.3333 43.5937C20 47.2396 10.2604 43.4375 6.61457 35.1042C2.96874 26.7708 6.77082 
      17.0312 15.1042 13.3854C19.2708 11.5625 23.8021 11.6146 27.7083 13.125L29.4271 8.69791C24.375 6.71875 18.5417 6.66666 13.1771 9.01041C2.4479 13.6979 -2.44793 26.25 2.23957 36.9792C6.92707 
      47.7083 19.4792 52.6042 30.2083 47.9167C40.9375 43.2292 45.8333 30.6771 41.1458 19.9479L36.8229 21.875C40.4687 30.2083 36.6667 39.9479 28.3333 43.5937Z'
            fill='#FC8019'
          ></path>
          <path
            d='M34.6875 6.04168C38.6458 7.55209 41.9792 10.625 43.8021 14.7917L48.125 12.8646C45.7813 7.50001 41.5104 3.59376 36.4063 1.61459L34.6875 6.04168Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M43.2812 16.1458L42.8646 15.2083C41.1979 11.4062 38.1771 8.54165 34.3229 7.03124L33.3333 6.61457L35.8333 0.260406L36.8229 0.62499C42.3437 2.81249 46.7187 6.97915 49.1146
           12.4479L49.5312 13.3854L43.2812 16.1458ZM36.0416 5.46874C39.6354 7.1354 42.5 9.89582 44.3229 13.4375L46.7708 12.3958C44.6875 8.22915 41.3021 
           4.94791 37.0312 3.02082L36.0416 5.46874Z'
            fill='#3D4152'
          ></path>
          <path
            d='M43.8021 14.7917C41.9792 10.625 38.6459 7.60416 34.6875 6.04166L28.6979 21.4062L43.8021 14.7917Z'
            fill='#FC8019'
          ></path>
          <path
            d='M26.8229 23.3854L34.1146 4.6875L35.1042 5.05208C39.4792 6.77083 42.9167 10.0521 44.7917 14.375L45.2084 15.3125L26.8229 23.3854ZM35.2604 7.39583L30.5729 19.4271L42.3959 14.2708C40.8334 11.25 38.3334 8.85417 35.2604 7.39583Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.7188 46.0417C14.9479 46.0417 8.4896 42.1354 5.62501 35.5208C1.71876 26.6666 5.78126 16.3021 14.6354 12.3958C18.9063 10.5208 23.6979 10.4167 28.0729 12.1354L29.0625 12.5L23.5938 26.5104L37.3438 20.4687L37.7604 21.4062C39.6354 25.6771 39.7396 30.4687 38.0209 34.8437C36.3021 39.2187 33.0209 42.6562 28.6979 44.5312C26.4583 45.5729 24.1146 46.0417 21.7188 46.0417ZM7.5521 34.6875C10.9896 42.5 20.1042 46.0416 27.9167 42.6562C31.7188 40.9896 34.5834 37.9687 36.0938 34.1146C37.4479 30.5729 37.5 26.7708 36.25 23.2812L19.8438 30.4687L26.3542 13.75C22.8125 12.6042 18.9583 12.8125 15.5208 14.3229C7.70835 17.7604 4.16668 26.875 7.5521 34.6875Z'
            fill='#3D4152'
          ></path>
          <path
            d='M15.9896 39.7396C17.4278 39.7396 18.5938 38.5737 18.5938 37.1354C18.5938 35.6972 17.4278 34.5312 15.9896 34.5312C14.5514 34.5312 13.3854 35.6972 13.3854 37.1354C13.3854 38.5737 14.5514 39.7396 15.9896 39.7396Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M15.9895 40.7813C14.5833 40.7813 13.2291 39.9479 12.6041 38.5937C12.1875 37.7083 12.1875 36.7187 12.552 35.7812C12.9166 34.8437 13.5937 34.1667 14.4791 33.75C15.3645 33.3333 16.3541 33.3333 17.2916 33.6979C18.2291 34.0625 18.9062 34.7396 19.3229 35.625C19.7395 36.5104 19.7395 37.5 19.375 38.4375C19.0104 39.375 18.3333 40.0521 17.4479 40.4687C16.9791 40.6771 16.4583 40.7813 15.9895 40.7813ZM15.9895 35.5208C15.7812 35.5208 15.5729 35.5729 15.3645 35.6771C15 35.8333 14.6875 36.1458 14.5312 36.5625C14.375 36.9792 14.375 37.3958 14.5312 37.7604C14.8958 38.5417 15.8333 38.9062 16.6145 38.5937C16.9791 38.4375 17.2916 38.125 17.4479 37.7083C17.6041 37.2917 17.6041 36.875 17.4479 36.5104C17.2916 36.1458 16.9791 35.8333 16.5625 35.6771C16.3541 35.5729 16.1458 35.5208 15.9895 35.5208Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2709 23.3333C15.5365 23.3333 16.5625 22.3073 16.5625 21.0417C16.5625 
                  19.776 15.5365 18.75 14.2709 18.75C13.0052 18.75 11.9792 19.776 11.9792 21.0417C11.9792 22.3073 13.0052 23.3333 14.2709 23.3333Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2708 24.375C13.8542 24.375 13.4375 24.3229 13.0729 24.1667C12.2396 23.8542 11.6146 23.2292 11.25 22.3958C10.8854 21.5625 10.8854 20.6771 11.1979 19.8438C11.5104 19.0104 12.1354 18.3854 12.9687 18.0208C14.6354 17.2917 16.6146 18.0729 17.3437 19.7396C18.0729 21.4063 17.2917 23.3854 15.625 24.1146C15.2083 24.2708 14.7396 24.375 14.2708 24.375ZM14.2708 19.8438C14.1146 19.8438 13.9583 19.8958 13.8021 19.9479C13.4896 20.1042 13.2812 20.3125 13.125 20.625C13.0208 20.9375 13.0208 21.25 13.125 21.5625C13.2292 21.875 13.4896 22.0833 13.8021 22.2396C14.1146 22.3438 14.4271 22.3438 14.7396 22.2396C15.3646 21.9792 15.625 21.25 15.3646 20.625C15.2083 20.1042 14.7396 19.8438 14.2708 19.8438Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M29.4271 39.1667C29.0104 39.1667 28.6458 39.1146 28.2292 38.9583C27.3958 38.6458 26.7708 38.0208 26.4062 37.2396L28.3333 36.4063C28.4375 36.7188 28.6979 36.9271 28.9583 37.0313C29.2708 37.1354 29.5833 37.1354 29.8438 37.0313C30.4167 36.7708 30.7292 36.0938 30.4688 35.4688L32.3958 34.6354C33.125 36.3021 32.3438 38.2292 30.7292 38.9583C30.3125 39.1146 29.8958 39.1667 29.4271 39.1667ZM36.1979 15.7292C34.9479 15.7292 33.75 15 33.1771 13.75L35.1042 12.9167C35.3646 13.4896 36.0417 13.8021 36.6667 13.5417C37.2396 13.2813 37.5521 12.6042 37.2917 11.9792L39.2188 11.1458C39.9479 12.8125 39.1667 14.7396 37.5521 15.4688C37.0833 15.6771 36.6667 15.7292 36.1979 15.7292Z'
            fill='#3D4152'
          ></path>
          <path
            d='M9.70388 29.8397L7.63013 29.6406L7.48081 31.1959L9.55456 31.395L9.70388 29.8397Z'
            fill='#3D4152'
          ></path>
          <path
            d='M12.0833 34.1146L10.0521 33.6979C10.1562 33.125 10.4166 32.5521 10.7812 32.0833L12.4479 33.3333C12.2396 33.5937 12.1354 33.8542 12.0833 34.1146ZM14.6875 29.5312L12.9687 28.3333C13.2291 27.9167 13.5937 27.6042 14.0104 27.2917L15.2083 29.0104C15 29.1146 14.8437 29.3229 14.6875 29.5312Z'
            fill='#3D4152'
          ></path>
          <path
            d='M20.6437 23.9917L18.7612 23.0992L18.2926 24.0875L20.1751 24.98L20.6437 23.9917Z'
            fill='#3D4152'
          ></path>
          <path
            d='M37.5399 9.7654L35.6562 8.87543L35.189 9.86436L37.0726 10.7543L37.5399 9.7654Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.2292 18.5417L21.1458 18.1771C21.25 17.6562 21.4583 17.1354 21.7708 16.7187L23.4896 17.9167C23.3333 18.0729 23.2812 18.3333 23.2292 18.5417ZM19.4271 17.1354L17.3958 16.5625C17.5521 16.0417 17.8125 15.5208 18.2292 15.1042L19.7396 16.5625C19.6354 16.7187 19.4792 16.9271 19.4271 17.1354ZM8.43749 
                  24.8437C8.22916 24.2708 8.22916 23.6458 8.54166 23.0729L10.4167 24.0625L8.43749 24.8437Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.0815 33.8223L20.2463 31.9137L19.4351 32.2687L20.2703 34.1772L21.0815 33.8223Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.3484 37.8959L22.5113 35.9881L21.7005 36.3439L22.5376 38.2517L23.3484 37.8959Z'
            fill='#3D4152'
          ></path>
          <path
            d='M22.2532 41.8217L21.8372 39.7803L20.5614 40.0404L20.9774 42.0817L22.2532 41.8217Z'
            fill='#3D4152'
          ></path>
          <path
            d='M28.0084 41.1457L26.8884 39.3891L25.4392 40.3131L26.5592 42.0697L28.0084 41.1457Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.9062 33.8021C23.9062 33.0729 24.2187 32.3959 24.7917 31.9792L26.0937 33.6459C26.0417 33.6459 26.0417 33.6979 26.0417 33.75L23.9062 33.8021Z'
            fill='#3D4152'
          ></path>
          <path
            d='M29.4826 28.8412L28.3087 27.1201L27.233 27.8538L28.4069 29.5749L29.4826 28.8412Z'
            fill='#3D4152'
          ></path>
          <path
            d='M30.3646 33.5417L29.7917 31.5104C29.9479 31.4583 30.1563 31.3542 30.2604 31.25L31.6146 32.8646C31.25 33.1771 30.8334 33.3854 30.3646 33.5417Z'
            fill='#3D4152'
          ></path>
          <path
            d='M35.2013 28.177L33.6899 26.7432L32.507 27.9901L34.0184 29.4239L35.2013 28.177Z'
            fill='#3D4152'
          ></path>
          <path
            d='M18.4758 29.8661L16.6481 28.8662L16.2981 29.5059L18.1258 30.5058L18.4758 29.8661Z'
            fill='#3D4152'
          ></path>
          <path
            d='M34.475 16.5408L32.6477 15.5402L32.2975 16.1797L34.1248 17.1804L34.475 16.5408Z'
            fill='#3D4152'
          ></path>
        </svg>
      ),
      name: 'pizza',
    },
    {
      icon: (
        <svg
          width='55'
          height='55'
          viewBox='0 0 50 50'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M28.3333 43.5937C20 47.2396 10.2604 43.4375 6.61457 35.1042C2.96874 26.7708 6.77082 
      17.0312 15.1042 13.3854C19.2708 11.5625 23.8021 11.6146 27.7083 13.125L29.4271 8.69791C24.375 6.71875 18.5417 6.66666 13.1771 9.01041C2.4479 13.6979 -2.44793 26.25 2.23957 36.9792C6.92707 
      47.7083 19.4792 52.6042 30.2083 47.9167C40.9375 43.2292 45.8333 30.6771 41.1458 19.9479L36.8229 21.875C40.4687 30.2083 36.6667 39.9479 28.3333 43.5937Z'
            fill='#FC8019'
          ></path>
          <path
            d='M34.6875 6.04168C38.6458 7.55209 41.9792 10.625 43.8021 14.7917L48.125 12.8646C45.7813 7.50001 41.5104 3.59376 36.4063 1.61459L34.6875 6.04168Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M43.2812 16.1458L42.8646 15.2083C41.1979 11.4062 38.1771 8.54165 34.3229 7.03124L33.3333 6.61457L35.8333 0.260406L36.8229 0.62499C42.3437 2.81249 46.7187 6.97915 49.1146
           12.4479L49.5312 13.3854L43.2812 16.1458ZM36.0416 5.46874C39.6354 7.1354 42.5 9.89582 44.3229 13.4375L46.7708 12.3958C44.6875 8.22915 41.3021 
           4.94791 37.0312 3.02082L36.0416 5.46874Z'
            fill='#3D4152'
          ></path>
          <path
            d='M43.8021 14.7917C41.9792 10.625 38.6459 7.60416 34.6875 6.04166L28.6979 21.4062L43.8021 14.7917Z'
            fill='#FC8019'
          ></path>
          <path
            d='M26.8229 23.3854L34.1146 4.6875L35.1042 5.05208C39.4792 6.77083 42.9167 10.0521 44.7917 14.375L45.2084 15.3125L26.8229 23.3854ZM35.2604 7.39583L30.5729 19.4271L42.3959 14.2708C40.8334 11.25 38.3334 8.85417 35.2604 7.39583Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.7188 46.0417C14.9479 46.0417 8.4896 42.1354 5.62501 35.5208C1.71876 26.6666 5.78126 16.3021 14.6354 12.3958C18.9063 10.5208 23.6979 10.4167 28.0729 12.1354L29.0625 12.5L23.5938 26.5104L37.3438 20.4687L37.7604 21.4062C39.6354 25.6771 39.7396 30.4687 38.0209 34.8437C36.3021 39.2187 33.0209 42.6562 28.6979 44.5312C26.4583 45.5729 24.1146 46.0417 21.7188 46.0417ZM7.5521 34.6875C10.9896 42.5 20.1042 46.0416 27.9167 42.6562C31.7188 40.9896 34.5834 37.9687 36.0938 34.1146C37.4479 30.5729 37.5 26.7708 36.25 23.2812L19.8438 30.4687L26.3542 13.75C22.8125 12.6042 18.9583 12.8125 15.5208 14.3229C7.70835 17.7604 4.16668 26.875 7.5521 34.6875Z'
            fill='#3D4152'
          ></path>
          <path
            d='M15.9896 39.7396C17.4278 39.7396 18.5938 38.5737 18.5938 37.1354C18.5938 35.6972 17.4278 34.5312 15.9896 34.5312C14.5514 34.5312 13.3854 35.6972 13.3854 37.1354C13.3854 38.5737 14.5514 39.7396 15.9896 39.7396Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M15.9895 40.7813C14.5833 40.7813 13.2291 39.9479 12.6041 38.5937C12.1875 37.7083 12.1875 36.7187 12.552 35.7812C12.9166 34.8437 13.5937 34.1667 14.4791 33.75C15.3645 33.3333 16.3541 33.3333 17.2916 33.6979C18.2291 34.0625 18.9062 34.7396 19.3229 35.625C19.7395 36.5104 19.7395 37.5 19.375 38.4375C19.0104 39.375 18.3333 40.0521 17.4479 40.4687C16.9791 40.6771 16.4583 40.7813 15.9895 40.7813ZM15.9895 35.5208C15.7812 35.5208 15.5729 35.5729 15.3645 35.6771C15 35.8333 14.6875 36.1458 14.5312 36.5625C14.375 36.9792 14.375 37.3958 14.5312 37.7604C14.8958 38.5417 15.8333 38.9062 16.6145 38.5937C16.9791 38.4375 17.2916 38.125 17.4479 37.7083C17.6041 37.2917 17.6041 36.875 17.4479 36.5104C17.2916 36.1458 16.9791 35.8333 16.5625 35.6771C16.3541 35.5729 16.1458 35.5208 15.9895 35.5208Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2709 23.3333C15.5365 23.3333 16.5625 22.3073 16.5625 21.0417C16.5625 
                  19.776 15.5365 18.75 14.2709 18.75C13.0052 18.75 11.9792 19.776 11.9792 21.0417C11.9792 22.3073 13.0052 23.3333 14.2709 23.3333Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M14.2708 24.375C13.8542 24.375 13.4375 24.3229 13.0729 24.1667C12.2396 23.8542 11.6146 23.2292 11.25 22.3958C10.8854 21.5625 10.8854 20.6771 11.1979 19.8438C11.5104 19.0104 12.1354 18.3854 12.9687 18.0208C14.6354 17.2917 16.6146 18.0729 17.3437 19.7396C18.0729 21.4063 17.2917 23.3854 15.625 24.1146C15.2083 24.2708 14.7396 24.375 14.2708 24.375ZM14.2708 19.8438C14.1146 19.8438 13.9583 19.8958 13.8021 19.9479C13.4896 20.1042 13.2812 20.3125 13.125 20.625C13.0208 20.9375 13.0208 21.25 13.125 21.5625C13.2292 21.875 13.4896 22.0833 13.8021 22.2396C14.1146 22.3438 14.4271 22.3438 14.7396 22.2396C15.3646 21.9792 15.625 21.25 15.3646 20.625C15.2083 20.1042 14.7396 19.8438 14.2708 19.8438Z'
            fill='#A6C44A'
          ></path>
          <path
            d='M29.4271 39.1667C29.0104 39.1667 28.6458 39.1146 28.2292 38.9583C27.3958 38.6458 26.7708 38.0208 26.4062 37.2396L28.3333 36.4063C28.4375 36.7188 28.6979 36.9271 28.9583 37.0313C29.2708 37.1354 29.5833 37.1354 29.8438 37.0313C30.4167 36.7708 30.7292 36.0938 30.4688 35.4688L32.3958 34.6354C33.125 36.3021 32.3438 38.2292 30.7292 38.9583C30.3125 39.1146 29.8958 39.1667 29.4271 39.1667ZM36.1979 15.7292C34.9479 15.7292 33.75 15 33.1771 13.75L35.1042 12.9167C35.3646 13.4896 36.0417 13.8021 36.6667 13.5417C37.2396 13.2813 37.5521 12.6042 37.2917 11.9792L39.2188 11.1458C39.9479 12.8125 39.1667 14.7396 37.5521 15.4688C37.0833 15.6771 36.6667 15.7292 36.1979 15.7292Z'
            fill='#3D4152'
          ></path>
          <path
            d='M9.70388 29.8397L7.63013 29.6406L7.48081 31.1959L9.55456 31.395L9.70388 29.8397Z'
            fill='#3D4152'
          ></path>
          <path
            d='M12.0833 34.1146L10.0521 33.6979C10.1562 33.125 10.4166 32.5521 10.7812 32.0833L12.4479 33.3333C12.2396 33.5937 12.1354 33.8542 12.0833 34.1146ZM14.6875 29.5312L12.9687 28.3333C13.2291 27.9167 13.5937 27.6042 14.0104 27.2917L15.2083 29.0104C15 29.1146 14.8437 29.3229 14.6875 29.5312Z'
            fill='#3D4152'
          ></path>
          <path
            d='M20.6437 23.9917L18.7612 23.0992L18.2926 24.0875L20.1751 24.98L20.6437 23.9917Z'
            fill='#3D4152'
          ></path>
          <path
            d='M37.5399 9.7654L35.6562 8.87543L35.189 9.86436L37.0726 10.7543L37.5399 9.7654Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.2292 18.5417L21.1458 18.1771C21.25 17.6562 21.4583 17.1354 21.7708 16.7187L23.4896 17.9167C23.3333 18.0729 23.2812 18.3333 23.2292 18.5417ZM19.4271 17.1354L17.3958 16.5625C17.5521 16.0417 17.8125 15.5208 18.2292 15.1042L19.7396 16.5625C19.6354 16.7187 19.4792 16.9271 19.4271 17.1354ZM8.43749 
                  24.8437C8.22916 24.2708 8.22916 23.6458 8.54166 23.0729L10.4167 24.0625L8.43749 24.8437Z'
            fill='#3D4152'
          ></path>
          <path
            d='M21.0815 33.8223L20.2463 31.9137L19.4351 32.2687L20.2703 34.1772L21.0815 33.8223Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.3484 37.8959L22.5113 35.9881L21.7005 36.3439L22.5376 38.2517L23.3484 37.8959Z'
            fill='#3D4152'
          ></path>
          <path
            d='M22.2532 41.8217L21.8372 39.7803L20.5614 40.0404L20.9774 42.0817L22.2532 41.8217Z'
            fill='#3D4152'
          ></path>
          <path
            d='M28.0084 41.1457L26.8884 39.3891L25.4392 40.3131L26.5592 42.0697L28.0084 41.1457Z'
            fill='#3D4152'
          ></path>
          <path
            d='M23.9062 33.8021C23.9062 33.0729 24.2187 32.3959 24.7917 31.9792L26.0937 33.6459C26.0417 33.6459 26.0417 33.6979 26.0417 33.75L23.9062 33.8021Z'
            fill='#3D4152'
          ></path>
          <path
            d='M29.4826 28.8412L28.3087 27.1201L27.233 27.8538L28.4069 29.5749L29.4826 28.8412Z'
            fill='#3D4152'
          ></path>
          <path
            d='M30.3646 33.5417L29.7917 31.5104C29.9479 31.4583 30.1563 31.3542 30.2604 31.25L31.6146 32.8646C31.25 33.1771 30.8334 33.3854 30.3646 33.5417Z'
            fill='#3D4152'
          ></path>
          <path
            d='M35.2013 28.177L33.6899 26.7432L32.507 27.9901L34.0184 29.4239L35.2013 28.177Z'
            fill='#3D4152'
          ></path>
          <path
            d='M18.4758 29.8661L16.6481 28.8662L16.2981 29.5059L18.1258 30.5058L18.4758 29.8661Z'
            fill='#3D4152'
          ></path>
          <path
            d='M34.475 16.5408L32.6477 15.5402L32.2975 16.1797L34.1248 17.1804L34.475 16.5408Z'
            fill='#3D4152'
          ></path>
        </svg>
      ),
      name: 'pizza',
    },
  ];

  const couponsList = [
    {
      discount: '15% OFF',
      title: 'xa85u4t98uq5',
      discription: 'FLAT 15% discount',
    },
    {
      discount: '15% OFF',
      title: 'xa85u4t98uq5',
      discription: 'FLAT 15% discount',
    },
    {
      discount: '15% OFF',
      title: 'xa85u4t98uq5',
      discription: 'FLAT 15% discount',
    },
    {
      discount: '15% OFF',
      title: 'xa85u4t98uq5',
      discription: 'FLAT 15% discount',
    },
  ];

  const userdata = [
    {
      username: 'sadhik123',
      firstName: 'Sadhik',
      lastName: 'shaik',
      Date: 'June 1, 2020, 08:22 AM',
      Address: 'Elm Street, 23 Yogyakarta 2,97 Km',
      phone: '9876543210',
      email: 'sadhik@gmail.com',
    },
    {
      username: 'sadhik123',
      firstName: 'Sadhik',
      lastName: 'shaik',
      Date: 'June 1, 2020, 08:22 AM',
      Address: 'Elm Street, 23 Yogyakarta 2,97 Km',
      phone: '9876543210',
      email: 'sadhik@gmail.com',
    },
    {
      username: 'sadhik123',
      firstName: 'Sadhik',
      lastName: 'shaik',
      Date: 'June 1, 2020, 08:22 AM',
      Address: 'Elm Street, 23 Yogyakarta 2,97 Km',
      phone: '9876543210',
      email: 'sadhik@gmail.com',
    },
  ];

  return (
    <RootGuard>
      <Routes>
        <Route path='/' element={<AppScreenLayout />}>
          <Route
            path={appRoutes.dashboard}
            element={<HomeScreen data={userdata} />}
          />
          <Route
            path={appRoutes.orders}
            element={<Orders order={order} socket={socket} />}
          />
          <Route
            path={appRoutes.orderHistory}
            element={<OrderHistory data={order} />}
          />
          <Route path={appRoutes.menuItems} element={<BasicMenu />} />
          <Route
            path={appRoutes.category}
            element={<BasicCategory icons={icons} />}
          />
          <Route
            path={appRoutes.coupons}
            element={<BasicCoupons couponsList={couponsList} />}
          />
          <Route path={appRoutes.riders} element={<BasicRiders />} />
          <Route path={appRoutes.users} element={<BasicUsers />} />
          <Route path={appRoutes.banners} element={<BasicBanners />} />
          <Route path={appRoutes.settings} element={<Settings />} />
        </Route>
      </Routes>
    </RootGuard>
  );
};

export default AppScreen;
