import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useResponsive } from '../../hooks/use-responsive';
import {
  Button,
  Divider,
  MenuItem,
  Popover,
  Switch,
  Typography,
} from '@mui/material';
import { NAV, HEADER } from '../../constants/config-layout';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useState, useEffect } from 'react';
import { menuItems } from '../../constants/menuItems';
import { useLocation } from 'react-router-dom';
import {
  getSettings,
  updateRestaurantStatusSettings,
} from '../../services/api';
import BasicCloseReasonModal from '../BasicCloseReasonModal';
import { toast, ToastContainer } from 'react-toastify';
import { storageKeys } from '../../constants/storagekeys';

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

const profile = localStorage.getItem(storageKeys.profile);
export default function Header({ onOpenNav, onClickLogout }) {
  const theme = useTheme();
  const [profileOpen, setProfileOpen] = useState(null);
  const { pathname } = useLocation();
  const [settings, setSettings] = useState<any>({});
  const [openSwitch, setSwitch] = useState<boolean>(false)
  const [reasonModal, setReasonModal] = useState(false);

  const handleProfileOpen = (event) => {
    setProfileOpen(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileOpen(null);
  };

  const lgUp = useResponsive({
    query: 'up',
    start: 'lg',
  });

  const fetchSettings = async () => {
    const response = await getSettings();
    if (response?.status === 200) {
      setSettings(response?.data);
      setSwitch(response?.data?.restaurant_open || false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const updateStatusSettings = async (
    status: boolean,
    closeReason?: string
  ) => {
    console.log(status, closeReason);
    if (status === false && closeReason === undefined) {
      setReasonModal(true);
    } else {
      const response = await updateRestaurantStatusSettings({
        restaurant_open: status,
        close_reason: closeReason ? closeReason : '',
      });
      if (response.status === 200) {
        toast('Status changed', {
          position: 'bottom-right',
        });
        fetchSettings();
      } 
    }
  };

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <MenuIcon />
        </IconButton>
      )}

      <Typography
        variant='h6'
        noWrap
        component='div'
        sx={{
          color: 'primary.main',
          fontWeight: 600,
          fontSize: '1.25rem',
          lineHeight: 1.6,
          letterSpacing: '0.0075em',
          textTransform: 'capitalize',
          '&:hover': {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        {menuItems.filter((item) => item.path === pathname)[0]?.title}
      </Typography>

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction='row' alignItems='center' spacing={1}>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography
            sx={{ color: !settings?.restaurant_open ? 'red' : 'green' }}
          >
            {settings?.close_reason || 'Opened'}
          </Typography>
          <span
            style={{
              color: settings?.restaurant_open ? 'green' : 'red',
            }}
          >
            ●
          </span>
          <Switch
            checked={openSwitch}
            onChange={(e) => updateStatusSettings(!settings?.restaurant_open)}
            name='loading'
            color='primary'
          />
        </Stack>
        <IconButton onClick={handleProfileOpen}>
          <AccountCircleIcon />
        </IconButton>
        <Popover
          open={!!profileOpen}
          anchorEl={profileOpen}
          onClose={handleProfileClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1,
              ml: 0.75,
              width: 200,
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2 }}>
            <Typography variant='subtitle2' noWrap>
              {JSON.parse(profile)?.name?.toUpperCase()}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

          <MenuItem
            disableRipple
            disableTouchRipple
            onClick={onClickLogout}
            sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
          >
            Logout
          </MenuItem>
        </Popover>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.WIDTH + 1}px)`,
          height: HEADER.H_DESKTOP,
        }),
        backgroundColor: 'rgb(249, 250, 251)',
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
      <BasicCloseReasonModal
        open={reasonModal}
        setOpen={setReasonModal}
        updateSettings={updateStatusSettings}
      />
      <ToastContainer />
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
