import CloseIcon from '@mui/icons-material/Close';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../../components/loading/Loading';
import { filterUsers, getAllUsers, updateUserStatus } from '../../services/api';

export const BasicUsers = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [addressData, setAddressData] = useState([]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.trim();
    setSearchQuery(query);
    if (query !== '') {
      debouncedSearchUsers(query);
    } else {
      fetchAllUsers();
    }
  };

  const handleFilterUser = async (query: string) => {
    const response = await filterUsers(query);
    if (response?.status === 200) {
      console.log('Response', response);
      setUserData(response?.data || []);
    }
  };

  const handleThreeDotsOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setMenuAnchor(null);
  };

  const fetchAllUsers = async () => {
    setLoading(true);
    const response = await getAllUsers(rowsPerPage, page);
    console.log('userdzr', response);
    if (response?.status === 200) {
      setTotalCount(response?.data?.count || 100);
      setUserData(response?.data?.users || []);
    } else {
      toast(response?.data || '');
    }
    console.log('Data from user', response);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const handleDelete = () => {
    // Handle delete action
    handleThreeDotsClose();
  };

  const handleUpdateStatus = async (status: string, userId: string) => {
    const response = await updateUserStatus({
      status,
      userId,
    });
    console.log('statusss', response, userId);
    fetchAllUsers();
  };

  const handleChangePage = (event, newPage) => {
    setSearchQuery('');
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchQuery('');
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchAllUsers();
  }, [page, rowsPerPage]);

  const debouncedSearchUsers = useCallback(debounce(handleFilterUser, 300), []);

  useEffect(() => {
    return () => {
      debouncedSearchUsers.cancel();
    };
  }, []);

  return (
    <Container>
      {loading && <Loading loading={loading} />}
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        alignItems={'center'}
        mt={3}
      >
        <Typography
          variant='h4'
          component={'h1'}
          sx={{ color: 'rgb(252, 128, 25)', fontWeight: 700 }}
        >
          All Users
        </Typography>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        >
          <IconButton>
            <SearchIcon sx={{ color: 'rgba(252, 128, 25, 1)' }} />
          </IconButton>
          <TextField
            placeholder='Search here'
            variant='standard'
            InputProps={{ disableUnderline: true }}
            inputProps={{ style: { color: '#646465' } }}
            value={searchQuery}
            onChange={handleSearch}
          />
        </Box>
      </Box>
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: '10px',
          marginTop: '20px',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    FirstName
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    LastName
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Email
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Phone
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Address
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Orders
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Created time
                  </Typography>
                </TableCell>
                <TableCell
                  style={{ borderBottom: '1px solid #ccc', paddingLeft: 0 }}
                >
                  <Typography sx={{ fontSize: '15px' }} fontWeight={700}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData &&
                userData.length > 0 &&
                userData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        {row.first_name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        {row.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        {row.email}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: '1px solid #ccc',
                        color: '#848684',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        {row.mobile}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                      <Typography
                        color=''
                        style={{
                          fontSize: '13px',
                          color: '#848684',
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setAddressData(row);
                            setOpenAddressModal(true);
                          }}
                        >
                          <PlaceOutlinedIcon
                            sx={{
                              color: 'rgba(252, 128, 25, 1)',
                              fontSize: '17px',
                            }}
                          />
                        </IconButton>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                      <Typography
                        style={{
                          fontSize: '13px',
                        }}
                      >
                        {row.user_status?.toUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                      <Typography
                        style={{
                          fontSize: '13px',
                        }}
                      >
                        {row.orderCount}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                      {moment(row?.created_time).format(
                        'YYYY-MM-DD HH:mm:ss A'
                      )}
                    </TableCell>

                    <TableCell style={{ borderBottom: '1px solid #ccc' }}>
                      <Button
                        onClick={() => {
                          const status =
                            row?.user_status === 'block' ? 'active' : 'block';
                          handleUpdateStatus(status, row?.user_id);
                        }}
                      >
                        <Typography
                          color={
                            row.user_status === 'block'
                              ? 'error.main'
                              : 'success.main'
                          }
                        >
                          {row.user_status === 'block' ? 'Unblock' : 'Block'}
                        </Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {searchQuery === '' && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component='div'
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </div>

      {openAddressModal && (
        <BasicModalAddress
          open={openAddressModal}
          setOpen={setOpenAddressModal}
          data={addressData}
        />
      )}
    </Container>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  border: 'none',
  borderRadius: 3,
  width: '90%',
  maxWidth: '600px',
};

const BasicModalAddress = ({ open, setOpen, data }) => {
  console.log('Data from modal', data?.addresses);
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      style={{ padding: '0px' }}
    >
      <Box sx={style}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <span style={{ color: '#3D4152', fontWeight: '600' }}>Address</span>
          <span onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </span>
        </Box>
        <Box mt={4}>
          {data?.addresses && data?.addresses.length > 0 ? (
            data?.addresses.map((eachAddress, index) => (
              <Stack direction={'column'} key={index} mb={2}>
                <Stack direction={'row'} spacing={2}>
                  <Typography>{index + 1}.</Typography>
                  <Box>
                    <Typography>{eachAddress?.receiver_name}</Typography>
                    <Typography>{eachAddress?.receiver_mobile}</Typography>
                    <Typography>{eachAddress?.street}</Typography>
                  </Box>
                </Stack>
              </Stack>
            ))
          ) : (
            <Typography>No address found</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
