import { Outlet } from 'react-router-dom';
import SideBar from '../sidebar/Sidebar';
import Header from '../header/Header';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useResponsive } from '../../hooks/use-responsive';
import { NAV, HEADER } from '../../constants/config-layout';
import { useDispatch } from 'react-redux';
import { clearAuthDetails } from '../../redux/reducers/auth.reducers';

const SPACING = 8;
const AppScreenLayout = () => {
  const dispatch = useDispatch();
  const [openNav, setOpenNav] = useState(false);

  const lgUp = useResponsive({
    query: 'up',
    start: 'lg',
  });

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(clearAuthDetails(null));
    window.location.href = '/';
  };

  return (
    <Box>
      <Header onOpenNav={() => setOpenNav(true)} onClickLogout={handleLogout} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <SideBar openNav={openNav} onCloseNav={() => setOpenNav(false)} />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            minHeight: 1,
            display: 'flex',
            flexDirection: 'column',
            py: `${HEADER.H_MOBILE + SPACING}px`,
            ...(lgUp && {
              px: 2,
              py: `${HEADER.H_DESKTOP + SPACING}px`,
              width: `calc(100% - ${NAV.WIDTH}px)`,
              height: `calc(100vh - ${HEADER.H_DESKTOP + SPACING}px)`,
            }),
           
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
export default AppScreenLayout;
